import api from '@/api'
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state() {
        return {
            orderHeaders: ['Date', 'Order ID', 'Farmer', 'Product Types', 'Total Amount', 'Phone'],
            ordersList: [
				{
					date: "10-10-2022",
					orderID: "23123",
					farmer: "Farmer",
					product: "Chem",
					total_amount: 1200,
					phone: 343254325,
				},
				{
					date: "10-10-2022",
					orderID: "23123",
					farmer: "Farmer",
					product: "Chem",
					total_amount: 1200,
					phone: 343254325,
				},
				{
					date: "10-10-2022",
					orderID: "23123",
					farmer: "Farmer",
					product: "Chem",
					total_amount: 1200,
					phone: 343254325,
				},
				{
					date: "10-10-2022",
					orderID: "23123",
					farmer: "Farmer",
					product: "Chem",
					total_amount: 1200,
					phone: 343254325,
				},
			],
			orderDetails:{}

        }
    },
    mutations: {
        updateOrdersList(state, ordersListData) {
			state.ordersList = [];
			ordersListData.forEach(item => state.ordersList.push(backendToFrontendFormatter(item)));
		},
		updateOrderDetails(state, { orderDetails }) {
			let data = backendToFrontendFormatter(orderDetails);
			state.orderDetails = data;
			const index = state.ordersList.findIndex(
				data => data.id == orderDetails.id
			);
			
			if (index != -1) {
				state.ordersList.splice(index, 1, data);
			}
		},
    },
    actions: {
        fetchOrdersList({ commit } ,orderStatus) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.ordersListURL.replace("{orderStatus}",orderStatus),
					method: "GET"
				}).then(ordersListData => {
					commit('updateOrdersList', ordersListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		fetchOrderDetails({commit},orderID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.orderDetailURL.replace("{id}",orderID),
					method: "GET"
				}).then(orderDetails => {
					commit('updateOrderDetails', {orderDetails});
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		setOrderDetails({ commit }, {data}) {
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url:api.orderDetailURL.replace("{id}",data.id),
						data: frontendToBackendFormatter(data),
						method:"PATCH"
					}).then(orderDetails => {
						commit("updateOrderDetails", { orderDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
		},
    }
}