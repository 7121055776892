import api from "../api";
const backendToFrontendFormatter = originalData => {
    const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
	state() {
        return {
            stateList:[],
            regions: [],
            sub_regions:[]
        };
	},
    mutations: {
        updateState(state, { states }) {
            state.stateList = [];
            states.forEach(item => state.stateList.push(backendToFrontendFormatter(item)))
        },
        updateRegions(state, { region }) {
            state.regions = [];
            region.forEach(item=>state.regions.push(backendToFrontendFormatter(item)))
        },
        updateSubRegion(state, { sub_regionData }) {
            state.sub_regions = [];
            sub_regionData.forEach(item=>state.sub_regions.push(backendToFrontendFormatter(item)))
        }
    },
    actions: {
        fetchState({ commit }) {
            return new Promise((resolve, reject) => {
                api.actionHandler({
                    url: api.stateURL,
                    method: 'GET',
                }).then(states => {
                    commit('updateState', { states })
                    resolve()
                }).catch(errMsg => reject(errMsg))
            })
        },
        
        fetchRegion({ commit },state) {
            return new Promise((resolve, reject) => {
                api.actionHandler({
                    url: api.regionURL.replace('{state}',state),
                    method:'GET',                    
                }).then(region => {
                    commit('updateRegions', { region })
                    resolve()
                }).catch(errMsg=>reject(errMsg))
            })
        },
        fetchSubRegion({ commit },region) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.sub_regionURL.replace('{region}',region),
                    method:'GET'
                }).then(sub_regionData => {
                    commit('updateSubRegion', {sub_regionData})
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },

    },
};
