import api from "@/api";
import axios from 'axios';
const backendToFrontendFormatter = originalData => {
	let news = JSON.parse(JSON.stringify(originalData));
	
	return news;
};

// const frontendToBackendFormatter = originalData => {
// 	let news = JSON.parse(JSON.stringify(originalData));
	
// 	return news.newsData
// };

export default {
	state() {
		return {
			loaded: false,
			newsList: [
				{
					tabTitle: "Loading...",
					tabContents: [
						{
							title: "Loading...",
							content: "Loading..."
						}
					]
				}
			]
		};
	},
	mutations: {
		updateNewsList(state, { newsList }) {
			state.newsList = [];
			newsList.results.forEach(news =>
				state.newsList.push(backendToFrontendFormatter(news))
			);
			state.loaded = true;
		},
		updateNewsDetails(state, { newsDetails }) {
			const index = state.newsList.findIndex(
				data => data.id == newsDetails.id
			);
			let data = backendToFrontendFormatter(newsDetails);
			if (index != -1) {
				state.newsList.splice(index, 1, data);
			} else {
				state.newsList.push(data);
			}
		},
		deleteNews(state, index) {
			state.newsList.splice(index, 1);
		}
	},
	actions: {
		fetchNewsList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newsListURL,
					method: "GET"
				})
					.then(newsList => {
						commit("updateNewsList", { newsList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchNewsDetails({ commit }, newsID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newsDetailsURL.replace("{id}", newsID),
					method: "GET"
				})
					.then(newsDetails => {
						commit("updateNewsDetails", { newsDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		// setNewsDetails({ commit }, actionObject) {
		// 	let editMode = actionObject.newsData.id != undefined;

		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: editMode
		// 				? api.newsDetailsURL.replace("{id}",actionObject.newsData.id)
		// 				: api.newsListURL,
		// 			data: frontendToBackendFormatter(actionObject),
		// 			method: editMode ? "PATCH" : "POST"
		// 		})
		// 			.then(newsDetails => {
		// 				commit("updateNewsDetails", { newsDetails });
		// 				resolve();
		// 			})
		// 			.catch(errMsg => reject(errMsg));
		// 	});
		// },
		setNewsDetails({ commit }, actionObject) {
			let editMode = actionObject.newsData.id != undefined;
			if(!editMode){
				return new Promise((resolve, reject) => {
					axios.post(api.newsListURL,
						actionObject.newsData,{
							headers: {
								'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
							}
						}
					).then(newsDetails => {
						commit("updateNewsDetails", { newsDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
			else {
				return new Promise((resolve, reject) => {
					axios.patch(api.newsDetailsURL.replace("{id}",actionObject.newsData.id	),
						actionObject.newsData,{
							headers: {
								'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
							}
						}
					).then(newsDetails => {
						commit("updateNewsDetails", { newsDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
		},
		deleteNews({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newsDetailsURL.replace("{id}", id),
					method: "DELETE"
				})
					.then(() => {
						commit("deleteNews", index);
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		}
	}
};
