import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	if (data.reportData.id !== undefined) {
		delete data.reportData.id;
		return data.reportData;
	} else {
		return data.reportData;
	}
};

export default {
	state() {
		return {
			loaded: false,
			tableHeaders: ["Date", "Report Name", "Section"],
			tableData: [
				// {
				// 	date: "2021-06-17",
				// 	name: "Mark",
				// 	section: "Otto"
				// },
				// {
				// 	date: "2021-06-16",
				// 	name: "Jacob",
				// 	section: "Throton"
				// },
				// {
				// 	date: "2021-06-15",
				// 	name: "Larry",
				// 	section: "TheBird"
				// }
			],
			sectionList: ["health_analysis","health_predictions_or_possibility_of_diseases","health_analysis_based_recommendations","other_health_recommendations","soil","crop"],
			cropList: ["Pepper", "Coffee", "Cardamom"],
			varieties: ["Variety1", "Variety2", "Variety3"],
			locality: ["Loc1", "Loc2", "Loc3"]
		};
	},
	mutations: {
		updateReportList(state, { reportList }) {
			state.tableData = [];
			reportList.results.forEach(report =>
				state.tableData.push(backendToFrontendFormatter(report))
			);
			state.loaded = true;
		},
		updateReportDetails(state, { reportDetails }) {
			const index = state.tableData.findIndex(
				data => data.id == reportDetails.id
			);
			let data = backendToFrontendFormatter(reportDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		deleteReport(state, index) {
			state.tableData.splice(index, 1);
		}
	},
	actions: {
		fetchReportList({ commit }, { searchQuery, startDate, endDate }) {
			var date = true;
			if (startDate == null || endDate == null) {
				date=false
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: date ? api.reportsListURL.replace("{searchQuery}", searchQuery).replace("{startDate}", startDate).replace("{endDate}", endDate) :
					api.reportsListURL.replace("{searchQuery}", searchQuery).replace("start_date={startDate}", "").replace("end_date={endDate}", ""),
					method: "GET"
				})
					.then(reportList => {
						commit("updateReportList", { reportList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchReportDetails({ commit }, {reportID}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.reportsDetailsURL.replace("{id}", reportID),
					method: "GET"
				})
					.then(reportDetails => {
						commit("updateReportDetails", { reportDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setReportDetails({ commit }, {actionObject}) {
			let editMode = actionObject.reportData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode
						? api.reportsDetailsURL.replace(
								"{id}",
								actionObject.reportData.id)
						: api.reportsListURL.replace("&start_date={startDate}","").replace("&end_date={endDate}","").replace("&search={searchQuery}", ""),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(reportDetails => {
						commit("updateReportDetails", { reportDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteReport({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.reportsDetailsURL.replace("{id}", id),
					method: "DELETE"
				})
					.then(() => {
						commit("deleteReport", index);
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		}
	}
};
