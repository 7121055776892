import api from "@/api";
import AttendanceData from "./Executive/AttendanceStore";
import TaskData from "./Executive/Task";
import LocationData from './Executive/Location'
import ImageData from './Executive/FarmerImages'

const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    modules: {
		AttendanceData,
		TaskData,
		LocationData,
		ImageData
    },
    state() {
        return {
            executiveList: [],
			executiveProfile: {},
			farmerCount:null
        }
    },
    mutations: {
        updateExecutiveList(state, executiveListData) {
            state.executiveList = [];
            executiveListData.results.forEach(item => state.executiveList.push(backendToFrontendFormatter(item)));
            
        },
        updateExecutiveProfile(state, executiveProfile) {	
            state.executiveProfile =backendToFrontendFormatter(executiveProfile)
            let data =backendToFrontendFormatter(executiveProfile)
			const Index = state.executiveList.findIndex(item => item.id === executiveProfile.id);
			if (Index !== -1) {
				state.executiveList.splice(Index,1,data)
			} else {
				state.executiveList.push(executiveProfile);			
				
			}
        },
        deleteExecutive(state, index) {
			state.executiveList.splice(index,1)
			
		},
		updateFarmerCount(state, count) {
			state.farmerCount=backendToFrontendFormatter(count.farmers.toString())
		}
    },
    actions: {   
        fetchExecutiveList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveListURL,
					method: "GET"
				}).then(executiveListData => {
					commit('updateExecutiveList', executiveListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        fetchExecutiveProfile({ commit },executiveID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveProfileURL.replace("{id}", executiveID),
					method: "GET"
				}).then(executiveProfile=> {
					commit('updateExecutiveProfile', executiveProfile);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        updateExecutiveProfile({ commit }, executiveData) {
			let edit = false;
			if (executiveData.id !== undefined) {
				edit = true;
			}

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit ? api.executiveProfileURL.replace("{id}", executiveData.id): api.executiveListURL,
					method: edit? "PATCH" : "POST",
					data: frontendToBackendFormatter(executiveData)
				}).then(executiveProfile => {
					commit('updateExecutiveProfile', executiveProfile);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
        deleteExecutive({ commit }, { id,index}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.executiveProfileURL.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteExecutive', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchFarmerCount({ commit }, executiveID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.farmerCountURL.replace("{id}", executiveID),
					method: "GET"
				}).then(count=> {
					commit('updateFarmerCount', count);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		}
    }

}