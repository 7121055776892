import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = (originalData, status) => {
	let data = JSON.parse(JSON.stringify(originalData));
	
	if (status) {
		data.news_status = status;
	}

	return data;
};

export default {
	state() {
		return {
			history: [],
			upcoming: [],
		};
	},
	mutations: {
		updateAppNewsList(state, { newsListData }) {
			const newsList = newsListData.map(news => backendToFrontendFormatter(news));
			state.history = newsList.filter(news => news.news_status === 'show');
			var newsData = newsList.filter(news => news.news_status != ('show'));
			state.upcoming = newsData;
				
		},
		updateAppNewsStatus(state, { newsItem }) {
			if (newsItem.news_status === 'created') {
				const newsIndex = state.history.findIndex(news => news.id === newsItem.id);
				if (newsIndex !== -1) {
					state.upcoming.unshift(state.history[newsIndex]);
					state.history.splice(newsIndex, 1);
				}
			} else {
				const newsIndex = state.upcoming.findIndex(news => news.id === newsItem.id);
				if (newsIndex !== -1) {
					state.history.unshift(state.upcoming[newsIndex]);
					state.upcoming.splice(newsIndex, 1);
				}
			}
		}
	},
	actions: {
		fetchAppNewsList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newsListURL,
					method: "GET"
				})
					.then(newsList => {
						commit("updateAppNewsList", { newsListData: newsList.results });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		sendAppNews({ commit }, { newsItem }) {
			const news_status = newsItem.news_status === 'show' ? 'hide' : 'show';
			delete newsItem.news_image;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newsDetailsURL.replace("{id}", newsItem.id),
					data: frontendToBackendFormatter({ news_status }),
					method: "PATCH"
				}).then(newsItem => {
					commit("updateAppNewsStatus", { newsItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
	},
};

