import api from "@/api";
import axios from "axios";

const backendToFrontendFormatter = (originalData) => {
	let disease = JSON.parse(JSON.stringify(originalData));

	return disease;
};

const frontendToBackendFormatter = originalData => {
	let disease = JSON.parse(JSON.stringify(originalData));
	if(disease.diseaseData.id!==undefined){
		delete disease.diseaseData.id
		return disease.diseaseData;
	} else {
		return disease.diseaseData
	}

};

export default {
	state() {
		return {
			tableData: [],
			nutrientCause: [],
			pestCause: [],
		};
	},
	mutations: {
		updateDiseasesList(state, { diseasesList }) {
			state.tableData = [];
			diseasesList.results.forEach((disease) =>
				state.tableData.push(backendToFrontendFormatter(disease))
			);
			state.loaded = true;
		},
		updateDiseasesDetails(state, { diseaseDetails }) {
			const index = state.tableData.findIndex(
				(disease) => disease.id == diseaseDetails.id
			);
			let disease = backendToFrontendFormatter(diseaseDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, disease);
			} else {
				state.tableData.push(disease);
			}
		},
		deleteDisease(state, index) {
			state.tableData.splice(index, 1);
		},
		updateNutrientCauseList(state, { nutrientCauseList }) {
			state.nutrientCause = [];
			nutrientCauseList.forEach((item) =>
				state.nutrientCause.push(backendToFrontendFormatter(item))
			);
		},
		updatePestCauseList(state, { pestCauseList }) {
			state.pestCause = [];
			pestCauseList.forEach((item) =>
				state.pestCause.push(backendToFrontendFormatter(item))
			);
		},
	},
	actions: {
		fetchDiseasesList({ commit }, { crop, searchQuery }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.diseasesListURL
						.replace("{crop}", crop)
						.replace("{searchQuery}", searchQuery),
					method: "GET",
				})
					.then((diseasesList) => {
						commit("updateDiseasesList", { diseasesList });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchDiseasesDetails({ commit }, { diseasesID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.diseasesDetailsURL.replace("{id}", diseasesID),
					method: "GET",
				})
					.then((diseaseDetails) => {
						commit("updateDiseasesDetails", { diseaseDetails });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		setDiseasesDetails({ commit }, { actionObject }) {
			let editDisease = actionObject.diseaseData.id != undefined;
			if (editDisease) {
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url:api.diseasesDetailsURL.replace("{id}",actionObject.diseaseData.id),
						method: "PATCH",
						data:frontendToBackendFormatter(actionObject)
					}).then((diseaseDetails) => {
						commit("updateDiseasesDetails", {
							diseaseDetails,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
				});
			} else {
				return new Promise((resolve, reject) => {
					axios
						.post(
							api.diseasesListURL
								.replace("?crop={crop}", "")
								.replace("&search={searchQuery}", ""),
							actionObject.diseaseData,
							{
								headers: {
									"Content-Type":
										"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
								},
							}
						)
						.then((diseaseDetails) => {
							commit("updateDiseasesDetails", {
								diseaseDetails,
							});
							resolve();
						})
						.catch((errMsg) => reject(errMsg));
				});
			}
		},
		deleteDisease({ commit }, { id, index }) {
			let diseasesID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.diseasesDetailsURL.replace("{id}", diseasesID),
					method: "DELETE",
				})
					.then(() => {
						commit("deleteDisease", index);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchNutrientCauseList({ commit }, crop) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchNutrientCauseURL.replace("{id}", crop),
					method: "GET",
				})
					.then((nutrientCauseList) => {
						commit("updateNutrientCauseList", {
							nutrientCauseList,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchPestCauseList({ commit }, crop) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPestCauseURL.replace("{id}", crop),
					method: "GET",
				})
					.then((pestCauseList) => {
						commit("updatePestCauseList", { pestCauseList });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
