import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	
	return data.itemData
};

export default {
	state() {
		return {
			loaded: false,
			types: [
				"farmer_data", "gov_data", "misc"
			],
			accessLevels: [
				"admin", "farmer", "executive", "fpo_admin", "govt_official"
			],
			tableData: [

			]
		};
	},
	mutations: {
		updateDataList(state, { dataList }) {
			state.tableData = [];
			dataList.results.forEach(data => state.tableData.push(backendToFrontendFormatter(data)))
			state.loaded = true;
		},
		updateDataDetails(state, { dataDetails }) {
			const index = state.tableData.findIndex(data => data.id == dataDetails.id)
			let data = backendToFrontendFormatter(dataDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		// deleteExpert(state, index) {
		// 	state.tableData.splice(index, 1)
		// }
	},
	actions: {
		fetchDataList({ commit }, {  startDate, endDate, searchQuery }) {
			var filter = true;
			if (startDate == null || endDate == null) {
				filter = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: filter ? api.dataListURL.replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{searchQuery}", searchQuery) :
					api.dataListURL.replace("start_date={startDate}","").replace("end_date={endDate}","").replace("{searchQuery}", searchQuery),
					method: "GET"
				}).then(dataList => {
					commit('updateDataList', { dataList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchDataDetails({ commit }, dataID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.dataDetailsURL.replace("{id}", dataID),
					method: "GET"
				}).then(dataDetails => {
					commit("updateDataDetails", { dataDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setDataDetails({ commit }, {actionObject}) {
			let editMode = actionObject.itemData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode ? api.dataDetailsURL.replace("{id}", actionObject.itemData.id) :
						api.dataListURL.replace("&start_date={startDate}","").replace("&end_date={endDate}","").replace("&search={searchQuery}", ""),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				}).then(dataDetails => {
					commit("updateDataDetails", { dataDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		// deleteExpert({ commit }, { id, index }) {
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: api.cropsExpertDetailsURL.replace("{id}",id),
		// 			method: 'DELETE',
		// 		}).then(() => {
		// 			commit('deleteExpert', index);
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	});
		// },
	},
};

