import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	if (data.incidentData.id !== undefined) {
		delete data.incidentData.id;
		return data.incidentData;
	} else {
		return data.incidentData;
	}
};

export default {
	state() {
		return {
			loaded: false,
			tableHeaders: ["Date", "Name or Tag", "Section"],
			tableData: [
				
			],
			sectionList: ['farmer_notice',"farmer_query","fvr",]
		};
	},
	mutations: {
		updateIncidentList(state, { incidentList }) {
			state.tableData = [];
			incidentList.results.forEach(data =>
				state.tableData.push(backendToFrontendFormatter(data))
			);
			state.loaded = true;
		},
		updateIncidentDetails(state, { incidentDetails }) {
			const index = state.tableData.findIndex(
				data => data.id == incidentDetails.id
			);
			let data = backendToFrontendFormatter(incidentDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		deleteIncident(state, index) {
			state.tableData.splice(index, 1)
		}
	},
	actions: {
		fetchIncidentList({ commit }, { farmerID, searchQuery, startDate, endDate, crop, land }) {
			var token = localStorage.getItem('decoded-token');
			var date = true;
			if (startDate == null || endDate == null) {
				date = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:date? (token ==='admin'? api.incidentListURL.replace("{id}", farmerID).replace("{searchQuery}", searchQuery).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{land}",land).replace("{crop}",crop):api.executiveIncidentsListURL.replace("{id}", farmerID).replace("{searchQuery}", searchQuery).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{land}",land).replace("{crop}",crop)) :
					(token ==='admin' ? api.incidentListURL.replace("{id}",farmerID).replace("{searchQuery}",searchQuery).replace("{land}",land).replace("{crop}",crop) .replace("start_date={startDate}","").replace("end_date={endDate}",""):api.executiveIncidentsListURL.replace("{id}",farmerID).replace("{searchQuery}",searchQuery).replace("{land}",land).replace("{crop}",crop) .replace("start_date={startDate}","").replace("end_date={endDate}","")),
					method: "GET"
				})
					.then(incidentList => {
						commit("updateIncidentList", { incidentList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchIncidentDetails({ commit }, { farmerID, itemID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.incidentDetailsURL.replace("{user_id}", farmerID).replace("{id}",itemID):api.executiveIncidentDetailsURL.replace("{user_id}", farmerID).replace("{id}",itemID),
					method: "GET"
				})
					.then(incidentDetails => {
						commit("updateIncidentDetails", { incidentDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setIncidentDetails({ commit }, { farmerID, actionObject }) {
			var token = localStorage.getItem('decoded-token');
			let editMode = actionObject.incidentData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode
						? (token === 'admin' ? api.incidentDetailsURL.replace("{id}",actionObject.incidentData.id).replace("{user_id}",farmerID): api.executiveIncidentDetailsURL.replace("{id}",actionObject.incidentData.id).replace("{user_id}",farmerID))
						:  (token === 'admin' ? api.incidentListURL.replace("{id}",farmerID).replace("?search={searchQuery}","").replace("&start_date={startDate}","").replace("&end_date={endDate}","").replace("&search={searchQuery}", "").replace("&land={land}","").replace("&crop={crop}",""):api.executiveIncidentsListURL.replace("{id}",farmerID).replace("?search={searchQuery}","").replace("&start_date={startDate}","").replace("&end_date={endDate}","").replace("&search={searchQuery}", "").replace("&land={land}","").replace("&crop={crop}","")),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(incidentDetails => {
						commit("updateIncidentDetails", { incidentDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteIncident({ commit }, { id, index, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin'? api.incidentDetailsURL.replace(
						"{id}",id).replace("{user_id}",farmerID):api.executiveIncidentDetailsURL.replace(
							"{id}",id).replace("{user_id}",farmerID),
					method: 'DELETE',
				}).then(() => {
					commit('deleteIncident', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
	}
};
