import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store.js'

Vue.use(Router)

var availablePaths = [{
		path: '/',
		redirect: '/login',
		component: () => import('./views/Login'),
		children: [{
			path: '/login',
			name: 'login',
			productionAvailable: true
		}],
		productionAvailable: true
	},
	{
		path: '/',
		redirect: '/dashboard',
		component: () => import('./layout/DashboardLayout'),
		meta: {
			requiresAuth: true
		},
		productionAvailable: true,
		children: [
			{ path: 'dashboard', name: 'dashboard', component: () => import('./views/Dashboard') },
			{ path: 'users', name: 'users', component: () => import('./views/Users') },
			{ path: 'users/adduser', name: 'add user', component: () => import('./views/AddUser'), props: true },
			{ path: 'report-analysis', name: 'reports', component: () => import('./views/Reports') },
			{ path: 'report-analysis/report', name: 'report-analysis-report', component: () => import('./views/FarmerSubViews/Report'), props: true },
			{ path: 'report-analysis/edit',   name: 'edit report', component: () => import('./views/AddReport'), props: true, },
			{ path: 'datamanagement', name: 'data management', component: () => import('./views/DataManagement') },
			{
				path: 'crops',
				component: () => import('./views/Crops'),
				children: [
					{ path: '',                             component: () => import('./views/CropsSubViews/CropsDashboard') },
					{ path: 'experts',                      component: () => import('./views/CropsSubViews/Experts'), name: 'experts'},
					{ path: 'experts/add',                  component: () => import('./views/CropsSubViews/AddExperts'), props: true, name: 'add experts' },
					{ path: 'crop-varieties',               component: () => import('./views/CropsSubViews/CropVarieties') },
					{ path: 'crop-varieties/add',           component: () => import('./views/CropsSubViews/AddCropVarieties'), props: true, name: 'add crop varieties' },
					{ path: 'farm-practices',               component: () => import('./views/CropsSubViews/FarmPractices'), props: true },
					{ path: 'farm-practices/add',           component: () => import('./views/CropsSubViews/AddFarmPractices'), props: true, name: 'add farm practices' },
					{ path: 'diseases',                     component: () => import('./views/CropsSubViews/Diseases') },
					{ path: 'diseases/add',                 component: () => import('./views/CropsSubViews/AddDiseases'), props: true, name: 'add diseases' },
					{ path: 'market-price',                 component: () => import('./views/CropsSubViews/MarketPrice') },
					{ path: 'fertilizers',                  component: () => import('./views/CropsSubViews/Fertilizers') },
					{ path: 'pesticides',                   component: () => import('./views/CropsSubViews/Pesticides') },
					{ path: 'fertilizers/add',              component: () => import('./views/CropsSubViews/AddFertilizer'), props: true, name: 'add fertilizer' },
					{ path: 'pesticides/add',               component: () => import('./views/CropsSubViews/AddPesticide'), props: true, name: 'add pesticide' },
					{ path: 'institutional-advisories',     component: () => import('./views/CropsSubViews/InstitutionalAdvisories') },
					{ path: 'institutional-advisories/add', component: () => import('./views/CropsSubViews/AddInstitutionalAdvisories'), props: true, name: 'add institutional advisories' },
					{ path: 'pests-and-attacks',            component: () => import('./views/CropsSubViews/PestsAndAttacks') },
					{ path: 'pests-and-attacks/add',        component: () => import('./views/CropsSubViews/AddPestsAndAttacks'), props: true, name: 'add pests and attacks' },
					{ path: 'nutrients',                    component: () => import('./views/CropsSubViews/Nutrients'), props: true },
					{ path: 'nutrients/add',                component: () => import('./views/CropsSubViews/AddNutrients'), props: true, name: 'add nutrients' },
					{ path: 'govt-schemes',                 component: () => import('./views/CropsSubViews/GovtSchemes') },
					{ path: 'geographic-data',              component: () => import('./views/CropsSubViews/GeographicData') },
					{ path: 'water-management',             component: () => import('./views/CropsSubViews/WaterManagement') },
					{ path: 'soil-reports',                 component: () => import('./views/CropsSubViews/SoilReports') },
					{ path: 'satellite-images',             component: () => import('./views/CropsSubViews/SatelliteImages') },
					{ path: 'meteorological-data',          component: () => import('./views/CropsSubViews/MeteorologicalData') },
					{ path: 'crop-calendar',                component: () => import('./views/CropsSubViews/CropCalendar') },
					{ path: 'misc',                         component: () => import('./views/CropsSubViews/Misc') },
				]
			}, {
				path: 'executive-section', component: () => import('./views/ExecutivesSection'), children: [
					{ path: '', component: () => import('./views/ExecutiveSubViews/ExecutiveDashboard') },
					{ path: 'add-executive', component: () => import('./views/ExecutiveSubViews/AddExecutive') },
					{ path: ':executiveID', redirect: ':executiveID/dashboard' },
					{ path: ':executiveID/dashboard', component: () => import('./views/ExecutiveSubViews/ExecutiveProfileDashboard'), props: true, name: 'executive profile dashboard' },
					{ path: ':executiveID/dashboard/edit-executive', component: () => import('./views/ExecutiveSubViews/AddExecutive'), props: true, name: 'edit executive profile' },
					{ path: ':executiveID/dashboard/location', component: () => import('./views/ExecutiveSubViews/Locations'), props: true },
					{ path: ':executiveID/dashboard/attendance', component: () => import('./views/ExecutiveSubViews/Attendance'), props: true ,name:'attendance'},
					{ path: ':executiveID/dashboard/tasks', component: () => import('./views/ExecutiveSubViews/Task'), props: true },
					{ path: ':executiveID/dashboard/tasks/add', component: () => import('./views/ExecutiveSubViews/AddTask'), props: true },
					{ path: ':executiveID/dashboard/tasks/:taskID/edit', component: () => import('./views/ExecutiveSubViews/AddTask'), props: true },
				]
			},
			{
				path: 'farmers-section',
				component: () => import('./views/FarmersSection'),
				
				children: [
					{ path: '', component: () => import('./views/FarmerSubViews/FarmerDashboard') },
					{ path: 'add-farmer',  component: () => import('./views/FarmerSubViews/AddFarmerProfile') },
					{ path: ':farmerID', redirect: ':farmerID/dashboard' },
					{ path: ':farmerID/dashboard', component: () => import('./views/FarmerSubViews/FarmerProfileDashboard'), props: true, name: 'farmer profile dashboard' },
					{ path: ':farmerID/dashboard/edit-farmer',  component: () => import('./views/FarmerSubViews/AddFarmerProfile'), props: true, name: 'edit farmer profile' },
					{ path: ':farmerID/dashboard/farm-profile', component: () => import('./views/FarmerSubViews/FarmProfile'), props: true },
					{ path: ':farmerID/dashboard/crop-health-analysis', component: () => import('./views/FarmerSubViews/CropHealthAnalysis'), props: true },
					{ path: ':farmerID/dashboard/crop-health-analysis/report', component: () => import('./views/FarmerSubViews/Report') },
					{ path: ':farmerID/dashboard/crop-yield-analysis', component: () => import('./views/FarmerSubViews/CropYieldAnalysis'), props: true },
					{ path: ':farmerID/dashboard/crop-yield-analysis/add',  component: () => import('./views/FarmerSubViews/AddCropYield'), props: true, name: 'add crop yield'  },
					{ path: ':farmerID/dashboard/crop-yield-analysis/edit',  component: () => import('./views/FarmerSubViews/AddCropYield'), props: true, name: 'edit crop yield' },
					{ path: ':farmerID/dashboard/disease-history', component: () => import('./views/FarmerSubViews/DiseaseHistory'), props: true },
					{ path: ':farmerID/dashboard/disease-history/add', component: () => import('./views/FarmerSubViews/AddDiseaseHistory'), props: true, name: 'add disease history'},
					{ path: ':farmerID/dashboard/disease-history/edit', component: () => import('./views/FarmerSubViews/AddDiseaseHistory'), props: true, name: 'edit disease history'},
					{ path: ':farmerID/dashboard/expenditure', component: () => import('./views/FarmerSubViews/Expenditure'), props: true },
					{ path: ':farmerID/dashboard/expenditure/add-income-and-expenses',  component: () => import('./views/FarmerSubViews/AddIncomeAndExpenses'), props: true, name: 'add income and expenses' },
					{ path: ':farmerID/dashboard/expenditure/edit-income-and-expenses',  component: () => import('./views/FarmerSubViews/AddIncomeAndExpenses'), props: true, name: 'edit income and expenses' },
					{ path: ':farmerID/dashboard/expenditure/add-cost-optimization',  component: () => import('./views/FarmerSubViews/AddCostOptimization'), props: true, name: 'add cost optimization' },
					{ path: ':farmerID/dashboard/expenditure/edit-cost-optimization',  component: () => import('./views/FarmerSubViews/AddCostOptimization'), props: true, name: 'edit cost optimization' },
					{ path: ':farmerID/dashboard/expenditure/report', component: () => import('./views/FarmerSubViews/Report'), props: true },
					{ path: ':farmerID/dashboard/incidents', component: () => import('./views/FarmerSubViews/Incidents'), props: true },
					{ path: ':farmerID/dashboard/incidents/add',  component: () => import('./views/FarmerSubViews/AddIncident') , props: true, name: 'add incident'},
					{ path: ':farmerID/dashboard/incidents/edit',  component: () => import('./views/FarmerSubViews/AddIncident'), props: true, name: 'edit incident' },
					{ path: ':farmerID/dashboard/incidents/report', component: () => import('./views/FarmerSubViews/Report'), props: true },
					{ path: ':farmerID/dashboard/productivity', component: () => import('./views/FarmerSubViews/Productivity'), props: true },
					{ path: ':farmerID/dashboard/productivity/add',  component: () => import('./views/FarmerSubViews/AddProductivity'), props: true, name: 'add productivity' },
					{ path: ':farmerID/dashboard/productivity/edit',  component: () => import('./views/FarmerSubViews/AddProductivity'), props: true, name: 'edit productivity' },
					{ path: ':farmerID/dashboard/recommendations', component: () => import('./views/FarmerSubViews/Recommendations'), props: true },
					{ path: ':farmerID/dashboard/recommendations/add',  component: () => import('./views/FarmerSubViews/AddRecommendation'), props: true, name: 'add recommendation' },
					{ path: ':farmerID/dashboard/recommendations/edit',  component: () => import('./views/FarmerSubViews/AddRecommendation'), props: true, name: 'edit recommendation' },
					{ path: ':farmerID/dashboard/recommendations/report', component: () => import('./views/FarmerSubViews/Report'), props: true },
					{ path: ':farmerID/dashboard/subscriptions', component: () => import('./views/FarmerSubViews/Subscriptions'), props: true},
					{ path: ':farmerID/dashboard/subscriptions/add',  component: () => import('./views/FarmerSubViews/AddSubscription') },
					{ path: ':farmerID/dashboard/subscriptions/edit',  component: () => import('./views/FarmerSubViews/AddSubscription'), props: true, name: 'edit subscriptions' },
					{ path: ':farmerID/dashboard/sales', redirect: ':farmerID/dashboard/subscriptions' },
					{ path: ':farmerID/dashboard/sales/add',  component: () => import('./views/FarmerSubViews/AddSales') },
					{ path: ':farmerID/dashboard/sales/edit',  component: () => import('./views/FarmerSubViews/AddSales'), props: true, name: 'edit sales' },
					{ path: ':farmerID/dashboard/fvr-and-feedback', component: () => import('./views/FarmerSubViews/FVRFeedback'), props: true,name:'fvr-feedback' },
					{ path: ':farmerID/dashboard/fvr/add', component: () => import('./views/FarmerSubViews/AddFVR'), props: true, name: 'add fvr' },
					{ path: ':farmerID/dashboard/fvr/edit', component: () => import('./views/FarmerSubViews/AddFVR'), props: true, name: 'edit fvr' },
					{ path: ':farmerID/dashboard/feedback/add', component: () => import('./views/FarmerSubViews/AddFeedback'), props: true,name:'add feedback' },
					{ path: ':farmerID/dashboard/feedback/edit', component: () => import('./views/FarmerSubViews/AddFeedback'), props: true, name: 'edit feedback' },
					{ path: ':farmerID/dashboard/agri-inputs', component: () => import('./views/FarmerSubViews/AgriInputs'), props: true, name: 'agri input analysis'},
					{ path: ':farmerID/dashboard/agri-inputs/add', component: () => import('./views/FarmerSubViews/AddAgriInputs'), props: true, name: 'add agri input'},
					{ path: ':farmerID/dashboard/agri-inputs/edit', component: () => import('./views/FarmerSubViews/AddAgriInputs'), props: true, name: 'edit agri inputs' },
					{ path:':farmerID/dashboard/images',component:()=>import('./views/ExecutiveSubViews/FarmerProfileImages.vue') ,props:true ,name:'Farmer profile images'}
				],
			},
			{
				path: 'ecommerce-section',
				component: () => import('./views/EcommerceSection'),
				
				children: [
					{ path: '', component: () => import('./views/EcommerceSubviews/EcommerceDashboard') },
					{ path: 'products', component: () => import('./views/EcommerceSubviews/Products') },
					{ path: 'categories', component: () => import('./views/EcommerceSubviews/Categories') },
					{ path: 'orders', component: () => import('./views/EcommerceSubviews/Orders') },
					{ path: 'products/add-product', component: () => import('./views/EcommerceSubviews/AddProducts') },
					{ path: 'products/:productID/edit-product' ,component: () => import('./views/EcommerceSubviews/AddProducts'), props: true},
					{ path: 'categories/add-category', component: () => import('./views/EcommerceSubviews/AddCategory') },
					{ path:'sell',component:()=>import('./views/EcommerceSubviews/Sell')}
				]
			},
			{ path: 'appmanagement', name: 'app management', component: () => import('./views/AppManagement') },
			{ path: 'calendar', name: 'calendar', component: () => import('./views/Calendar') },
			{ path: 'contact', name: 'contact', component: () => import('./views/Contact') },
			{ path: 'news', name: 'news', component: () => import('./views/News') },
			{ path: 'news/addnews', name: 'add news', component: () => import('./views/AddNews'), props: true },
			{ path: 'profile', name: 'Profile', component: () => import('./views/Profile') },
			{ path: 'settings', name: 'Settings', component: () => import('./views/Settings') },
		]
	},
	{
		path: '*',
		name: 'not found',
		component: () => import('./views/NotFound'),
		productionAvailable: true,
	},
]

const router = new Router({
	mode: 'history',
	linkActiveClass: 'active',
	routes: process.env.NODE_ENV === 'production' ? availablePaths.filter((route) => route.productionAvailable) : availablePaths,
	scrollBehavior() {
		return { x: 0, y: 0, behavior: 'smooth' }
	},
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// This route requires auth, check if authenticated
		// If not, redirect to login page
		if (!store.getters.isAuthenticated) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			})
		} else {
			next();
		}
	} else {
		if (to.name == 'login' && store.getters.isAuthenticated) {
			// Redirect to the Dashboard as the user attempted to view the
			// login page while being authenticated already
			next({
				path: '/dashboard',
			});
		} else {
			next();
		}
	}
})

export default router;
