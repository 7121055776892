import Vue from "vue";
import Vuex from "vuex";

import accountData from "./AccountStore";
import cropData from "./CropsStore";
import userData from "./UserStore";
import farmerData from "./FarmerStore";
import reportAnalysisData from "./ReportAnalysisStore";
import newsData from "./NewsStore";
import dataManagementData from "./DataManagementStore";
import appManagementData from "./AppManagementNewStore";
import regionsData from './RegionStore'
import calendarStore from "./CalendarStore.js";
import notificationStore from "./NotificationStore.js";
import appManagementStore from "./AppManagementStore.js";
import profileStore from "./ProfileStore.js";
import ExecutiveData from './ExecutiveStore'
import DashboardData from './Dashboard';
import EcommerceData from './EcommerceStore'

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		accountData,
		cropData,
		userData,
		farmerData,
		newsData,
		reportAnalysisData,
		dataManagementData,
		appManagementData,
		regionsData,
		ExecutiveData,
		DashboardData,
		EcommerceData
	},
	state: {
		// These will be converted into modules
		calendar: calendarStore,
		notifications: notificationStore,
		appManagement: appManagementStore,
		profileData: profileStore,
	},
	mutations: {
	},
	actions: {
	},
	getters: {
	}
});

export default store;
