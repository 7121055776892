import api from '@/api'
import axios from 'axios';
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state() {
        return {
            productList: [
				
			],
			productDetails: {},
			product_id:null

        }
    },
    mutations: {
        updateProductList(state, productListData) {
			state.productList = [];
			productListData.forEach(item => state.productList.push(backendToFrontendFormatter(item)));
		},
		updateProductDetails(state, { productDetails }) {
			state.product_id=productDetails.id
			let data = backendToFrontendFormatter(productDetails);
			state.productDetails = productDetails;
			const index = state.productList.findIndex(
				data => data.id == productDetails.id
			);
			
			if (index != -1) {
				state.productList.splice(index, 1, data);
			} else {
				state.productList.push(data);
			}
		},
    },
    actions: {
        fetchProductList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.productListURL,
					method: "GET"
				}).then(productListData => {
					commit('updateProductList', productListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		fetchProductDetails({ commit },id) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.productDetailsURL.replace("{id}",id),
					method: "GET"
				}).then(productDetails => {
					commit('updateProductDetails', { productDetails });
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		setProductDetails({ commit }, actionObject) {
			let editMode = actionObject.productID != undefined;
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url:editMode ? api.productDetailsURL.replace("{id}",actionObject.productID):api.productListURL,
						data: frontendToBackendFormatter(actionObject.data),
						method:editMode? "PATCH" :"POST"
					}).then(productDetails => {
						commit("updateProductDetails", { productDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
		},
		uploadProductImage({ commit } ,{imageData,productID}) {
			return new Promise((resolve, reject) => {
				axios.post(api.uploadProductImageURL.replace("{id}",productID),
				imageData,{
						headers: {
							'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
						}
					}
				).then(productDetails => {
					commit("updateProductDetail", { productDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		deleteProductImage({ commit }, { id, index, miscData: { productID } }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteImageURL.replace("{id}",id).replace("{product_id}", productID),
					method: "DELETE"
				}).then(() => {
					commit('deleteLan', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
    }
}