import api from "@/api";
import Orders from "./Ecommerce/Orders";
import Product from "./Ecommerce/Products";
import Categories from "./Ecommerce/Categories";
const backendToFrontendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
	modules: {
		Orders,
		Product,
		Categories,
	},
	state() {
		return {
			productCount: null,
			orderCount: null,
			saleCount: null,
			farmerProducts: [],
			tableHeaders:['Farmer Name' ,'Product Name','Address','Quantity', 'Unit','Amount']
		};
	},
	mutations: {
		updateDashboardData(state, dashboardData) {
			state.productCount = null;
			state.orderCount = null;
			const data = backendToFrontendFormatter(dashboardData);
			state.productCount = data.products;
			state.orderCount = data.all_orders;
			state.saleCount = data.completed_orders;
		},
		updateFarmerProducts(state, farmerProducts) {
			state.farmerProducts = [];
			farmerProducts.forEach(item=>state.farmerProducts.push(backendToFrontendFormatter(item)))
		}
	},
	actions: {
		fetchDashboardData({ commit }) {
			return new Promise((resolve, reject) =>
				api
					.actionHandler({
						url: api.dashboardDataURL,
						method: "GET",
					})
					.then((dashboardData) => {
						commit("updateDashboardData", dashboardData);
						resolve();
					})
					.catch((errMsg) => reject(errMsg))
			);
		},
		fetchFarmerProductList({ commit }) {
			return new Promise((resolve, reject) =>
				api.actionHandler({
						url: api.farmerProductsURL,
						method: "GET",
					})
					.then((farmerProducts) => {
						commit("updateFarmerProducts", farmerProducts);
						resolve();
					})
					.catch((errMsg) => reject(errMsg))
			);
		},
	},
};
