<template>
<base-dropdown class="nav-link pr-0 pl-0" position="right">
	<a slot="title" class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click.prevent="markNotificationsRead">
		<i class="ni ni-bell-55" :class="{ 'text-danger': !$store.state.notifications.notificationsRead }"></i>
	</a>

	<div class="dropdown-header noti-title">
		<h6 class="text-overflow m-0">Notifications</h6>
	</div>
	<div v-for="(notification, index) in $store.state.notifications.notificationList" :key="index">
		<a class="dropdown-item" @click="removeNotification(index)" :href="notification.linkTo">
			{{ notification.notificationTitle }}
		</a>
	</div>
	<div class="dropdown-divider"></div>
	<div class="p-3 notification-status">No new notifications</div>
</base-dropdown>
</template>
<script>
export default {
	name: 'notification-panel',
	data() {
		return {}
	},
	methods: {
		markNotificationsRead() {
			this.$store.state.notifications.notificationsRead = true;
		},
		removeNotification(index) {
			this.$store.commit('removeNotification', index);
		}
	}
};
</script>
<style scoped>
.notification-status {
	font-size: .8125rem;
}
</style>
