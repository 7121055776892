import * as chartConfigs from "@/components/Charts/config";
import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.qualityUnit = data.quality_unit;
	delete data.quality_unit;

	data.quantityUnit = data.quantity_unit;
	delete data.quantity_unit;

	data.predictedYield = data.predicted_quantity;
	delete data.predicted_quantity;

	data.predictedUnit = data.predicted_quantity_unit;
	delete data.predicted_quantity_unit;

	return data;
};

const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.quantity_unit = data.quantityUnit;
	delete data.quantityUnit;

	data.quality_unit = data.qualityUnit;
	delete data.qualityUnit;

	data.predicted_quantity = data.predictedYield;
	delete data.predictedYield;

	data.predicted_quantity_unit = data.predictedUnit;
	delete data.predictedUnit;

	return data;
};

export default {
	state() {
		return {
			farmerID: -1,
			landID: -1,
			tableHeaders: ["Year", "Quantity", "Quality", "Predicted"],
			cropYieldList: [],

			quantityUnits: ["kg", "gm", "litr"],
			qualityUnits: ["gl250"],
			predictedUnits: ["kg", "gm", "litr"],

			chartData: [
				{
					title: "Quantity of Produce",
					subTitle: "",
					chartData: {
						labels: [],
						datasets: [
							{
								label: "Quantity",
								data: [],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				{
					title: "Quality of Produce",
					subTitle: "",
					chartData: {
						labels: [],
						datasets: [
							{
								label: "Quality",
								data: [],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				{
					title: "Yield Prediction",
					subTitle: "19 December - 17 June",
					chartData: {
						labels: ["2018", "2019", "2020", "2021", "2022"],
						datasets: [
							{
								label: "Yield",
								data: [25, 20, 30, 22, 17, 29],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
			],
		};
	},
	mutations: {
		updateCropYield(state, { cropYieldData }) {
			const index = state.cropYieldList.findIndex(
				(c) => c.id == cropYieldData.id
			);
			if (index === -1) {
				state.cropYieldList.unshift(
					backendToFrontendFormatter(cropYieldData)
				);
			} else {
				state.cropYieldList.splice(
					index,
					1,
					backendToFrontendFormatter(cropYieldData)
				);
			}
		},
		updateCropYields(state, { cropYields, farmerID, landID }) {
			state.cropYieldList = cropYields.map((cropYield) =>
				backendToFrontendFormatter(cropYield)
			);
			state.farmerID = farmerID;
			state.landID = landID;
		},
		deleteCropYield(state, cropYieldID) {
			const index = state.cropYieldList.findIndex(
				(c) => c.id == cropYieldID
			);
			if (index !== -1) {
				state.cropYieldList.splice(index, 1);
			}
		},
	},

	actions: {
		updateCropYield({ commit }, { farmerID, cropYield }) {
			const editCropYield = cropYield.id !== undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropYieldListURL
						.replace("{user_id}", farmerID)
						.replace("?land={land_id}", "")
						.replace("&crop={crop}", "")
						.replace(
							"/{id}/",
							editCropYield ? `/${cropYield.id}/` : ""
						),
					method: editCropYield ? "PATCH" : "POST",
					data: frontendToBackendFormatter(cropYield),
				})
					.then((cropYieldData) => {
						commit("updateCropYield", { cropYieldData, farmerID });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		deleteCropYield({ commit, rootState }, { id }) {
			const farmerID = rootState.farmerData.cropYieldData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteCropYieldURL
						.replace("{user_id}", farmerID)
						.replace("{id}", id),
					method: "DELETE",
				})
					.then(() => {
						commit("deleteCropYield", id);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		fetchCropYield({ commit }, { farmerID, landID, crop }) {
			var token = localStorage.getItem("decoded-token");
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:
						token === "admin"
							? api.cropYieldListURL
									.replace("{user_id}", farmerID)
									.replace("/{id}/", "")
									.replace("{land_id}", landID)
									.replace("{crop}", crop)
							: api.executiveCropYieldListURL
									.replace("{user_id}", farmerID)
									.replace("/{id}/", "")
									.replace("{land_id}", landID)
									.replace("{crop}", crop),
					method: "GET",
				})
					.then((cropYieldData) => {
						commit("updateCropYields", {
							cropYields: cropYieldData.results,
							farmerID,
							landID,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
