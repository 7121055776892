import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let item = JSON.parse(JSON.stringify(originalData));

	return item;
};

const frontendToBackendFormatter = originalData => {
	let item = JSON.parse(JSON.stringify(originalData));
	if (item.itemData.id !== undefined) {
		delete item.itemData.id;
		return item.itemData;
	} else {
		return item.itemData;
	}
};

export default {
	state() {
		return {
			loaded: false,
			headers: [
				"Pest Name",
				"Attack name",
				"Remedy",
				"Details/Symptoms",
				"Sources/Reason"
			],
			tableData: [
				// [ 'Pest1', 'Root', 'Remedy', 'Details syptomsnsd sdks dsod w ddw dwd wd wd wd wd wd g ege ge diw of the sjd soen e,e,sddsd', 'souces or sdjsdowwod reaspn fdnfdifdfoeeeofmeofmeofeofmeoeg geeg.oe eog.3o g3.gosg.3g.93g3 g3'],
				// [ 'Pest2', 'Stem', 'Remedy', 'Details syptomsnsd sdks dsod w ddw dwd wd wd wd wd wd g ege ge diw of the sjd soen e,e,sddsd', 'souces or sdjsdowwod reaspn fdnfdifdfoeeeofmeofmeofeofmeoeg geeg.oe eog.3o g3.gosg.3g.93g3 g3'],
				// [ 'Worm', 'Leaf', 'Remedy', 'Details syptomsnsd sdks dsod w ddw dwd wd wd wd wd wd g ege ge diw of the sjd soen e,e,sddsd', 'souces or sdjsdowwod reaspn fdnfdifdfoeeeofmeofmeofeofmeoeg geeg.oe eog.3o g3.gosg.3g.93g3 g3'],
				// [ 'Hookworm', 'Root', 'Remedy', 'Details syptomsnsd sdks dsod w ddw dwd wd wd wd wd wd g ege ge diw of the sjd soen e,e,sddsd', 'souces or sdjsdowwod reaspn fdnfdifdfoeeeofmeofmeofeofmeoeg geeg.oe eog.3o g3.gosg.3g.93g3 g3'],
			],
			pesticides:[]
		};
	},
	mutations: {
		updatePestsAndAttacksList(state, { pestsAndAttacksList }) {
			state.tableData = [];
			pestsAndAttacksList.results.forEach(item =>
				state.tableData.push(backendToFrontendFormatter(item))
			);
			state.loaded = true;
		},
		updatePestsAndAttacksDetails(state, { pestsAndAttacksDetails }) {
			const index = state.tableData.findIndex(
				item => item.id == pestsAndAttacksDetails.id
			);
			let item = backendToFrontendFormatter(pestsAndAttacksDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, item);
			} else {
				state.tableData.push(item);
			}
		},
		deletePestAndAttacks(state, index) {
			state.tableData.splice(index,1)
		},
		updatePesticide(state, { pesticide }) {
			state.pesticides = [];
			pesticide.forEach(item => state.pesticides.push(backendToFrontendFormatter(item)));

		}
	},
	actions: {
		fetchPestsAndAttacksList({ commit }, { crop,searchQuery }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPestsAndAttacksListURL.replace("{crop}",crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				})
					.then(pestsAndAttacksList => {
						commit("updatePestsAndAttacksList", {
							pestsAndAttacksList
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchPestsAndAttacksDetails({ commit }, { pestsAndAttacksID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPestsAndAttacksDetailsURL.replace(
						"{id}",
						pestsAndAttacksID
					),
					method: "GET"
				})
					.then(pestsAndAttacksDetails => {
						commit("updatePestsAndAttacksDetails", {
							pestsAndAttacksDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setPestsAndAttacksDetails({ commit }, {actionObject}) {
			let editPestsAndAttacks = actionObject.itemData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editPestsAndAttacks
						? api.fetchPestsAndAttacksDetailsURL.replace(
								"{id}",
								actionObject.itemData.id)
						: api.fetchPestsAndAttacksListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editPestsAndAttacks ? "PATCH" : "POST"
				})
					.then(pestsAndAttacksDetails => {
						commit("updatePestsAndAttacksDetails", {
							pestsAndAttacksDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deletePestAndAttacks({ commit }, { id, index }) {
			let pestsAndAttacksID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:   api.fetchPestsAndAttacksDetailsURL.replace("{id}",pestsAndAttacksID),
					method: "DELETE"
				}).then(() => {
					commit("deletePestAndAttacks",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		fetchPesticide({ commit },  crop) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPesticideURL.replace("{id}",crop),
					method: "GET"
				}).then(pesticide => {
						commit("updatePesticide", {pesticide});
						resolve();
					}).catch(errMsg => reject(errMsg));
			});
		},
	}
};
