import api from "@/api";

const backendToFrontendFormatter = (originalData, diseaseList) => {
	let data = JSON.parse(JSON.stringify(originalData));

	const disease = diseaseList ? diseaseList.find(d => d.id == data.dieseas)?.dieseas_name : undefined;
	if (disease) {
		data.disease = disease;
	} else {
		data.disease = "Unknown name";
	}

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	if (data.diseaseHistoryData.id !== undefined) {
		delete data.diseaseHistoryData.id;
	}
	
	return data.diseaseHistoryData;
};

export default {
	state() {
		return {
			farmerID: -1,
			landID: -1,
			tableHeaders: ["Date", "Name Of Disease", "Precautions", "Details"],
			tableData: [
				
			],
			diseases: [
				"Aster Yellow",
				"Black knot",
				"Bacterial ring rot",
				"Clubroot",
				"Fire blight",
				"Basal rot",
				"Apple Scab",
				"Cedar Apple Rust"
			]
		};
	},
	mutations: {
		updateDiseaseHistoryList(state, { diseaseList, farmerID, cropDiseaseList }) {
			state.tableData = diseaseList.map(d => backendToFrontendFormatter(d, cropDiseaseList));
			state.farmerID = farmerID;
		},
		updateDiseaseHistoryDetails(state, { diseaseDetails, cropDiseaseList }) {
			const index = state.tableData.findIndex(data => data.id == diseaseDetails.id);
			let data = backendToFrontendFormatter(diseaseDetails, cropDiseaseList);
			if (index != -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		deleteDiseaseHistory(state, index) {
			state.tableData.splice(index, 1)
		}
	},
	actions: {
		fetchDiseaseHistoryList({ commit, rootState }, { farmerID, landID, crop }) {
			var token = localStorage.getItem('decoded-token');
			const cropDiseaseList = rootState.cropData.diseases.tableData;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.diseaseHistoryListURL
						.replace("{land}", landID).replace("{id}", farmerID).replace("{crop}",crop)
						:api.executiveDiseaseHistoryURL.replace("{id}", farmerID)
						.replace("{land}", landID).replace("{crop}",crop)
						,
					method: "GET"
				}).then(diseases => {
					commit("updateDiseaseHistoryList", { diseaseList: diseases.results, 
						farmerID, cropDiseaseList });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		fetchDiseaseHistoryDetails({ commit, rootState }, { farmerID, itemID }) {
			const cropDiseaseList = rootState.cropData.diseases.tableData;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.diseaseHistoryDetailsURL
						.replace("{user_id}", farmerID)
						.replace("{id}", itemID),
					method: "GET"
				}).then(diseaseDetails => {
					commit("updateDiseaseHistoryDetails", { diseaseDetails, cropDiseaseList });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setDiseaseHistoryDetails({ commit, rootState }, { farmerID, actionObject }) {
			const cropDiseaseList = rootState.cropData.diseases.tableData;
			let editMode = actionObject.diseaseHistoryData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode
						? api.diseaseHistoryDetailsURL
								.replace("{id}", actionObject.diseaseHistoryData.id)
								.replace("{user_id}", farmerID)
						: api.diseaseHistoryListURL
								.replace("?land={land}", "").replace("&{crop}={crop}","")
								.replace("{id}", farmerID),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(diseaseDetails => {
						commit("updateDiseaseHistoryDetails", { diseaseDetails, cropDiseaseList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteDiseaseHistory({ commit }, { id, index, farmerID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.diseaseHistoryDetailsURL.replace(
						"{id}",id).replace("{user_id}",farmerID),
					method: 'DELETE',
				}).then(() => {
					commit('deleteDiseaseHistory', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
	}
};
