import api from "@/api";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	data.lat = parseFloat(data.latitude);
	data.lng = parseFloat(data.longitude);
	delete data.latitude;
	delete data.longitude
	return data;
};

// const frontendToBackendFormatter = originalData => {
// 	const data = JSON.parse(JSON.stringify(originalData));
// 	return data;
// };
export default {
    state() {
		return {
			locationList: [
				
			],
		}
    },
	mutations: {
		updateLocationList(state, locationsListData) {
			state.locationList = [];
			locationsListData.forEach(item => state.locationList.push(backendToFrontendFormatter(item)));
		}
	},
	actions: {
		fetchLocationsList({ commit }, { executiveID, date }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.exeLocationURL.replace("{id}",executiveID).replace("{date}",date),
					method: "GET"
				}).then(locationsListData => {
					commit('updateLocationList', locationsListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		}
	}
}