import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	if (data.fvrData) {
		if (data.fvrData.id !== undefined) {
			delete data.fvrData.id;
			return data.fvrData;
		} else {
			return data.fvrData;
		}
	} else {
		if (data.feedbackData.id !== undefined) {
			delete data.feedbackData.id;
			return data.feedbackData;
		} else {
			return data.feedbackData;
		}
	}
};

export default {
	state() {
		return {
			loaded: false,
			tables: [
                {
                    name: "FVR",
                    headers: ["Date", "Name", "Report"],
                    data: [
                        // {
                        //     date: "2021-06-17",
                        //     name: "Visit #13",
                        //     report: "Found x and y and z. Noticed abc.",
                        // },
                        // {
                        //     date: "2021-06-16",
                        //     name: "Visit #12",
                        //     report: "Found x and y and z. Noticed abc.",
                        // },
                        // {
                        //     date: "2021-06-15",
                        //     name: "Visit #17",
                        //     report: "Found x and y and z. Noticed abc.",
                        // },
                    ],
                },
                {
                    name: 'Feedback',
                    headers: ["Date", "Recommendation", "Feedback"],
                    data: [
                        // {
                        //     date: "2021-06-17",
                        //     recommendation: "Water more",
                        //     feedback: "Good",
                        // },
                        // {
                        //     date: "2021-06-12",
                        //     recommendation: "More fertilizer",
                        //     feedback: "Good",
                        // },
                        // {
                        //     date: "2021-06-10",
                        //     recommendation: "Water less",
                        //     feedback: "Bad",
                        // },
                    ],
                }
            ],
		};
	},
	mutations: {
		updateFVRList(state, { fvrList }) {
			state.tables[0].data = [];
			fvrList.results.forEach(data =>
				state.tables[0].data.push(backendToFrontendFormatter(data))
			);
			state.loaded = true;
		},
		updateFeedbackList(state, { feedbackList }) {
			state.tables[1].data = [];
			feedbackList.results.forEach(data =>
				state.tables[1].data.push(backendToFrontendFormatter(data))
			);
			state.loaded = true;
		},
		updateFVRDetails(state, { fvrDetails }) {
			const index = state.tables[0].data.findIndex(
				data => data.id == fvrDetails.id
			);
			let item = backendToFrontendFormatter(fvrDetails);
			if (index != -1) {
				state.tables[0].data.splice(index, 1, item);
			} else {
				state.tables[0].data.push(item);
			}
		},
		updateFeedbackDetails(state, { feedbackDetails }) {
			const index = state.tables[1].data.findIndex(
				data => data.id == feedbackDetails.id
			);
			let item = backendToFrontendFormatter(feedbackDetails);
			if (index != -1) {
				state.tables[1].data.splice(index, 1, item);
			} else {
				state.tables[1].data.push(item);
			}
		},
		deleteFvr(state, index) {
			state.tables[0].data.splice(index,1)
		},
		deleteFeedback(state, index) {
			state.tables[1].data.splice(index,1)
		}
	},
	actions: {
		fetchFVRList({ commit }, { farmerID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.fvrListURL.replace("{id}", farmerID):api.executiveFvrListURL.replace("{id}", farmerID),
					method: "GET"
				})
					.then(fvrList => {
						commit("updateFVRList", {
							fvrList
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchFeedbackList({ commit }, { farmerID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin'? api.feedbackListURL.replace("{id}", farmerID):api.executiveFeedbackListURL.replace("{id}", farmerID),
					method: "GET"
				})
					.then(feedbackList => {
						commit("updateFeedbackList", { feedbackList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchFVRDetails({ commit }, { farmerID, itemID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin'? api.fvrDetailsURL
						.replace("{user_id}", farmerID)
						.replace("{id}", itemID):api.executiveFvrDetailsURL
						.replace("{user_id}", farmerID)
						.replace("{id}", itemID),
					method: "GET"
				})
					.then(fvrDetails => {
						commit("updateFVRDetails", {
							fvrDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchFeedbackDetails({ commit }, { farmerID, itemID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:token === 'admin'? api.feedbackDetailsURL.replace("{user_id}", farmerID)
					.replace("{id}", itemID):api.executiveFeedbackDetailsURL.replace("{user_id}", farmerID)
					.replace("{id}", itemID),
					method: "GET"
				})
					.then(feedbackDetails => {
						commit("updateFeedbackDetails", {
							feedbackDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setFVRDetails({ commit }, { actionObject, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			let editMode = actionObject.fvrData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode
						? (token === 'admin' ? api.fvrDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.fvrData.id):api.executiveFvrDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.fvrData.id))
						: (token === 'admin' ? api.fvrListURL.replace("{id}",farmerID):api.executiveFvrListURL.replace("{id}",farmerID)),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(fvrDetails => {
						commit("updateFVRDetails", {
							fvrDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setFeedbackDetails({ commit }, { actionObject, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			let editMode = actionObject.feedbackData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode? (token==='admin' ? api.feedbackDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.feedbackData.id):api.executiveFeedbackDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.feedbackData.id))
						: (token==='admin' ? api.feedbackListURL.replace("{id}", farmerID):api.executiveFeedbackListURL.replace("{id}", farmerID)),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(feedbackDetails => {
						commit("updateFeedbackDetails", {
							feedbackDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteFvr({ commit }, { id, index, miscData }) {
			console.log('id',id);
			var token = localStorage.getItem('decoded-token');
			const farmerID = miscData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.fvrDetailsURL.replace("{user_id}", farmerID).replace("{id}", id):api.executiveFvrDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteFvr', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		deleteFeedback({ commit }, { id,index, miscData }) {
			var token = localStorage.getItem('decoded-token');
			const farmerID = miscData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.feedbackDetailsURL.replace("{user_id}", farmerID).replace("{id}", id):api.executiveFeedbackDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteFeedback', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		}
	}
};
