import api from "@/api";
import axios from "axios";
import AgriInputStore from "./FarmerSubItems/AgriInputStore"
import CropYieldStore from "./FarmerSubItems/CropYieldStore";
import RecommendationStore from "./FarmerSubItems/RecommendationStore";
import expenditureStore from "./FarmerSubItems/ExpenditureStore.js";
import incidentsStore from "./FarmerSubItems/IncidentsStore.js";
import diseaseHistoryStore from "./FarmerSubItems/DiseaseHistoryStore.js";
import farmProfileStore from "./FarmerSubItems/FarmProfileStore.js";
import fvrFeedbackStore from "./FarmerSubItems/FVRFeedbackStore";
import productivityStore from "./FarmerSubItems/ProductivityStore";
import cropHealthAnalysisStore from "./FarmerSubItems/CropHealthAnalysisStore";
import subscriptionsStore from "./FarmerSubItems/SubscriptionsStore";
import farmerReport from  './FarmerSubItems/FarmerRepoprt'

const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.mobileNo = data.mobile_number;
	delete data.mobile_number;
	
	

	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	data.mobile_number = data.mobileNo;
	delete data.mobileNo;
	return data;
};

export default {
	modules: {
		farmProfileData: farmProfileStore,
		agriInputData: AgriInputStore,
		cropHealthAnalysisData: cropHealthAnalysisStore,
		cropYieldData: CropYieldStore,
		recommendationData: RecommendationStore,
		expenditureData: expenditureStore,
		incidentsData: incidentsStore,
		diseaseHistoryData: diseaseHistoryStore,
		productivityData: productivityStore,
		fvrFeedbackData: fvrFeedbackStore,
		subscriptionData: subscriptionsStore,
		farmerReport:farmerReport
	},
	state() {
		return {
			farmerList: [
				/* { id: 2, name: "Farmer 1"}, 
				{ id: 3, name: "Farmer 2"},  */
			],

			farmerProfile: {},
			farmerLandDetails:{},

			headerCardData: [
				{
					title: "Farmer",
					content: "970",
					icon: "fa fa-user",
					iconGradient: "gradient-blue",
					changePercent: "0%",
					changePositive: true
				},
				{
					title: "Crop",
					content: "3",
					icon: "fa fa-leaf",
					iconGradient: "gradient-green",
					changePercent: "0%",
					changePositive: true
				},
				{
					title: "Variety",
					content: "0",
					icon: "fa fa-user",
					iconGradient: "gradient-orange",
					changePercent: "0%",
					changePositive: false
				}
			],

			varieties: ["Variety1", "Variety2", "Variety3"],
			locality: ["Loc1", "Loc2", "Loc3"],
			subscription: ["PlanA", "PlanB", "PlanC"],
			grade: ["Grade1", "Grade2", "Grade3"],
			executive: ["Exe1", "Exe2", "Exe3"],
			landList: ["Land 1", "Land 2", "Land 3"],
			areaUnits: ["cents", "acres", "hectares"],
			farmerLands: [],
			farmerCrops: [],
			farmer_id: null,
			farmerCount:null
		};
	},
	mutations: {
		updateFarmerProfile(state, farmerProfile) {
			state.farmer_id = farmerProfile.id;
			state.farmerProfile = backendToFrontendFormatter(farmerProfile);
			
			const farmerIndex = state.farmerList.findIndex(farmer => farmer.id === farmerProfile.id);
			if (farmerIndex !== -1) {
				state.farmerList[farmerIndex].name = farmerProfile.name;
			} else {
				state.farmerList.push(farmerProfile);			
				
			}
		},
		updateFarmerList(state, farmerListData) {
			state.farmerList = []
			state.farmerCount=farmerListData.count
			farmerListData.results.forEach(item=>state.farmerList.push(item))
			// state.headerCardData[0].content = farmerListData.count.toString();
		},
		updateFarmerLands(state, { farmerLand }) {	
			state.farmerLands=[]
			farmerLand.forEach(item => state.farmerLands.push(backendToFrontendFormatter(item)));
		},
		updateFarmerCrops(state, { farmerCrop }) {
			state.farmerCrops = [];
			farmerCrop.forEach(item => state.farmerCrops.push(backendToFrontendFormatter(item)));
		},
		deleteFarmer(state, farmerID) {
			const farmerIndex = state.farmerList.findIndex(farmer => farmer.id == farmerID);
			if (farmerIndex !== -1) {
				state.farmerList.splice(farmerIndex, 1);
			}
			if (state.farmerProfile.id == farmerID) {
				state.farmerProfile = {}
			}
		},
		updateFarmerLandDetails(state, farmerLandProfile) {
			state.farmerLandDetails = backendToFrontendFormatter(farmerLandProfile);

			
		},
		deleteLand(state, index) {
			state.farmerLandDetails.splice(index,1)
			
		},

		//Executive Dashboard
		updateExecutiveFarmerList(state,exeFarmerListData) {
			state.farmerList=[]
			exeFarmerListData.forEach(item => state.farmerList.push(item));
			state.headerCardData[0].content = exeFarmerListData.length.toString();
		}

	},
	actions: {
		updateFarmerProfile({ commit }, farmerProfileData) {
			var token =localStorage.getItem('decoded-token')
			let editFarmer = false;
			if (farmerProfileData.id !== undefined) {
				editFarmer = true;
			}

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editFarmer ? (token ==='admin'?api.farmerProfileURL.replace("{id}",farmerProfileData.id):api.executiveFarmerProfile.replace("{id}",farmerProfileData.id)): (token==='admin'? api.addFarmerURL:api.exeFarmerPostURL),
					method: editFarmer ? "PATCH" : "POST",
					data: frontendToBackendFormatter(farmerProfileData)
				}).then(farmerProfile => {
					commit('updateFarmerProfile', farmerProfile);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		updateFarmerLandDetails({ commit }, { farmerID, landData }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:api.updateFarmerlandDetails.replace("{user_id}",farmerID).replace("{id}",landData.id),
					method: "PATCH",
					data: frontendToBackendFormatter(landData)
				}).then(farmerProfile => {
					commit('updateFarmerProfile', farmerProfile);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
			
		},
		uploadProfilePic({ commit }, { data, farmerID }) {
			return new Promise((resolve, reject) => {
				axios.patch(api.farmerProfileUploadURL.replace("{id}",farmerID),
					data,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				).then(farmerProfile => {
					commit("updateFarmerProfile",  farmerProfile );
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
			
		},
		fetchFarmerProfile({ rootState, commit }, farmerID) {
			var token =localStorage.getItem('decoded-token')
			return new Promise((resolve, reject) => {
				if (rootState.farmerData.farmerProfile.id == farmerID) {
					resolve(rootState.farmerData.farmerProfile);
				}

				api.actionHandler({
					url: token ==='admin' ? api.farmerProfileURL.replace("{id}", farmerID):api.executiveFarmerProfile.replace("{id}", farmerID),
					method: "GET"
				}).then(farmerProfile => {
					commit('updateFarmerProfile', farmerProfile);
					resolve(rootState.farmerData.farmerProfile);
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchFarmerLandDetails({  commit }, farmerID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmerLandDetailsURL.replace("{id}", farmerID),
					method: "GET"
				}).then(farmerLandProfile => {
					commit('updateFarmerLandDetails', farmerLandProfile);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchFarmerList({ commit } ,{page ,pageSize ,region ,sub_region ,search}) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: (region===null || sub_region===null)?api.farmerListURL.replace("{page_size}" ,pageSize).replace("{page}",page).replace("{search}",search).replace("&region={region}","").replace("&sub_region={sub_region}",""):api.farmerListURL.replace("{page_size}" ,pageSize).replace("{page}",page).replace("{search}",search).replace("{region}",region).replace("{sub_region}",sub_region),
					method: "GET"
				}).then(farmerListData => {
					console.log(farmerListData)
					commit('updateFarmerList', farmerListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		fetchFarmerland({ commit },farmerID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.landDropdownURL.replace("{id}",farmerID),
					method: "GET"
				}).then(farmerLand => {
					commit('updateFarmerLands', {farmerLand});
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		fetchFarmerCrops({ commit },landID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.cropDropDownURL.replace("{id}",landID),
					method: "GET"
				}).then(farmerCrop => {
					commit('updateFarmerCrops', {farmerCrop});
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		deleteFarmer({ commit }, { id: farmerID }) {
			var token =localStorage.getItem('decoded-token')
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.farmerProfileURL.replace("{id}", farmerID):api.executiveFarmerProfile.replace("{id}", farmerID),
					method: "DELETE"
				}).then(() => {
					commit('deleteFarmer', farmerID);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		deleteLand({ commit }, { id,index,miscData:{farmerID} }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.updateFarmerlandDetails.replace("{id}",id).replace("{user_id}", farmerID),
					method: "DELETE"
				}).then(() => {
					// location.reload()
					commit('deleteLand', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},


		//Executive Dashboard

		fetchExecutiveFarmerList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveFarmerListURL,
					method: "GET"
				}).then(exeFarmerListData => {
					commit('updateExecutiveFarmerList', exeFarmerListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
	}
};