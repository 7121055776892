import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	data.startDate = data.start_date;
	delete data.start_date;

	data.endDate = data.end_date;
	delete data.end_date;

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	data.start_date = data.startDate;
	delete data.startDate;

	data.end_date = data.endDate;
	delete data.endDate;

	return data;
};

export default {
	state() {
		return {
			tableData:{
				headers: ["Name", "Start Date", "End Date"],
				data: [
					/* {
						name: 'Test',
						startDate: '22',
						endDate: '34',
					} */
				],
			},
		};
	},
	mutations: {
		updateProductivityList(state, { productivityList }) {
			state.tableData.data = productivityList.map(p => backendToFrontendFormatter(p));
			state.loaded = true;
		},
		updateProductivityDetails(state, { productivityDetails }) {
			const index = state.tableData.data.findIndex(
				data => data.id == productivityDetails.id
			);
			let item = backendToFrontendFormatter(productivityDetails);
			if (index != -1) {
				state.tableData.data.splice(index, 1, item);
			} else {
				state.tableData.data.push(item);
			}
		},
		deleteProductivity(state, productivityID) {
			const index = state.tableData.data.findIndex(p => p.id == productivityID);
			if (index !== -1) {
				state.tableData.data.splice(index, 1);
			}
		}
	},
	actions: {
		fetchProductivity({ commit }, { farmerID, searchQuery, land, crop }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.productivityListURL.replace("{user_id}", farmerID).replace("{searchQuery}", searchQuery).replace("{land}", land)
					.replace("{crop}",crop):api.executiveProductivityURL.replace("{user_id}", farmerID).replace("{searchQuery}", searchQuery).replace("{land}", land)
					.replace("{crop}",crop),
					method: "GET"
				})
					.then(productivityList => {
						commit("updateProductivityList", {
							productivityList
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchProductivityDetails({ commit }, { farmerID, itemID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.productivityDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				})
					.then(productivityDetails => {
						commit("updateProductivityDetails", {
							productivityDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setProductivity({ commit }, { actionObject, farmerID }) {
			let editMode = actionObject.productivityData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode
						? api.productivityDetailsURL
							.replace("{user_id}", farmerID)
							.replace(
								"{id}",
								actionObject.productivityData.id
							).replace("?search={searchQuery}","").replace("&land={land}","").replace("&crop={crop}","")
						: api.productivityListURL.replace(
							"{user_id}",
							farmerID).replace("?search={searchQuery}","").replace("&land={land}","").replace("&crop={crop}",""),
					data: frontendToBackendFormatter(actionObject.productivityData),
					method: editMode ? "PATCH" : "POST"
				})
					.then(productivityDetails => {
						commit("updateProductivityDetails", {
							productivityDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteProductivity({ commit }, { id, miscData }) {
			const farmerID = miscData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.productivityDetailsURL.replace("{user_id}", farmerID)
						.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteProductivity', id);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
	}
};
