import api from "@/api";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

// const frontendToBackendFormatter = originalData => {
// 	const data = JSON.parse(JSON.stringify(originalData));
// 	return data;
// };
export default {
    state() {
		return {
			tableHeaders: ["Date", "Check in", "Check out", "Duration"],
			attendanceList: [
				
			],
		}
    },
	mutations: {
		updateAttendanceList(state, attendanceListData) {
			state.attendanceList = [];
			attendanceListData.forEach(item => state.attendanceList.push(backendToFrontendFormatter(item)));
		}
	},
	actions: {
		fetchAttendanceList({ commit },executiveID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.attendanceListURL.replace("{id}",executiveID),
					method: "GET"
				}).then(attendanceListData => {
					commit('updateAttendanceList', attendanceListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		}
	}
}