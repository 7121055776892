import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let advisory = JSON.parse(JSON.stringify(originalData));

	return advisory;
};

const frontendToBackendFormatter = originalData => {
	let advisory = JSON.parse(JSON.stringify(originalData));
	if (advisory.advisoryData.id !== undefined) {
		delete advisory.advisoryData.crop
		delete advisory.advisoryData.id
		return advisory.advisoryData;
	} else {
		return advisory.advisoryData
	}


};

export default {
	state() {
		return {
			loaded: false,
			headers: ['Name', 'Area', 'Details', 'File'],
			tableData: [
				['Mark', 'Generic Area', 'Details lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'https://qa.agritech.deepflow.in/media/profile_photo.png'],
				['Jane', 'Land 1', 'Details lorsds sdi dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Caracl_%2801%29%2C_Paris%2C_d%C3%A9cembre_2013.jpg'],
				['Wilson', 'Land 2', 'Sdijsd sum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'],
				['Wendy', 'Land 2', 'Oh lorem si offer sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Koala_climbing_tree.jpg/240px-Koala_climbing_tree.jpg'],
			],
		};
	},
	mutations: {
		updateAdvisoriesList(state, { advisoryList }) {
			state.tableData = [];
			advisoryList.results.forEach(advisory => state.tableData.push(backendToFrontendFormatter(advisory)))
			state.loaded = true;
		},
		updateAdvisoriesDetails(state, { advisoryDetails }) {
			const index = state.tableData.findIndex(advisory => advisory.id == advisoryDetails.id)
			let advisory = backendToFrontendFormatter(advisoryDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, advisory);
			} else {
				state.tableData.push(advisory);
			}
		},
		deleteInstitutionalAdvisories(state, index) {
			state.tableData.splice(index, 1);
		}
	},
	actions: {
		fetchAdvisoriesList({ commit },{crop,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchAdvisoriesListURL.replace("{crop}",crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(advisoryList => {
					commit('updateAdvisoriesList', { advisoryList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchAdvisoriesDetails({ commit }, advisoryID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fethAdvisoriesDetailsURL.replace("{id}", advisoryID),
					method: "GET"
				}).then(advisoryDetails => {
					commit("updateAdvisoriesDetails", { advisoryDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setAdvisoriesDetails({ commit }, actionObject) {
			let editAdvisory = actionObject.advisoryData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editAdvisory ? api.fethAdvisoriesDetailsURL.replace("{id}", actionObject.advisoryData.id) :
						api.fetchAdvisoriesListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editAdvisory ? "PATCH" : "POST"
				}).then(advisoryDetails => {
					commit("updateAdvisoriesDetails", { advisoryDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteInstitutionalAdvisories({ commit }, { id, index }) {
			let advisoryID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.fethAdvisoriesDetailsURL.replace("{id}", advisoryID),
					method: "DELETE"
				}).then(() => {
					commit("deleteInstitutionalAdvisories",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
	},
};
