<template>
<div class="loader-wrapper" :class="{'d-flex': displayLoader}">
	<slot>
		<img src="@/assets/loader.svg" alt="loader">
	</slot>
</div>
</template>
<script>
export default {
	name: "loader",
	props: {
		displayLoader: {
			type: Boolean,
			description: "Boolean to display the loader or not",
			default: false,
		},
	}
};
</script>
<style>
</style>
