import api from "@/api";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state() {
		return {
			tableHeaders: ["Task Date", "Task Name", "Status"],
			taskList: [
				
            ],
			taskDetails: {},
			farmerList:[]
		}
    },
	mutations: {
		updateTaskList(state, taskListData) {
			state.taskList = [];
			taskListData.forEach(item => state.taskList.push(backendToFrontendFormatter(item)));
        },
        updateTaskDetails(state, taskDetails) {
            let data = backendToFrontendFormatter(taskDetails);
            state.taskDetails = data;
            const index = state.taskList.findIndex(item => item.id == taskDetails.id);
            if (index !== -1) {
                state.taskList.splice(index,1,data)
            } else {
                state.taskList.push(data)
            }
        },
        deleteTask(state, index) {
			state.taskList.splice(index,1)
			
		},
		updateExeFarmersList(state, farmerListData) {
			state.farmerList = [];
			farmerListData.forEach(item => state.farmerList.push(backendToFrontendFormatter(item)));
			
		}
	},
	actions: {
		fetchTaskList({ commit },executiveID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.taskListURL.replace("{id}",executiveID),
					method: "GET"
				}).then(taskListData => {
					commit('updateTaskList', taskListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        fetchTaskDetails({ commit }, { executiveID, taskID }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.taskDetailsURL.replace('{executive_id}',executiveID).replace("{id}", taskID),
					method: "GET"
				}).then(taskDetails => {
					commit('updateTaskDetails', taskDetails);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        updateTask({ commit }, { executiveID, data ,taskID}) {
			let edit = false;
			if (taskID !== undefined) {
				edit = true;
			}

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit ? api.taskDetailsURL.replace('{executive_id}',executiveID).replace("{id}", taskID): api.taskListURL.replace("{id}",executiveID),
					method: edit ? "PATCH" : "POST",
					data: frontendToBackendFormatter(data)
				}).then(taskDetails => {
					commit('updateTaskDetails', taskDetails);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
        },
        deleteTask({ commit }, { id,index,miscData: { executiveID } }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.taskDetailsURL.replace('{executive_id}',executiveID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteTask', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchExeFarmersList({ commit },executiveID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.exeFarmerListURL.replace("{id}",executiveID),
					method: "GET"
				}).then(farmerListData => {
					commit('updateExeFarmersList', farmerListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
	}
}
