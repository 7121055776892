import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let price = JSON.parse(JSON.stringify(originalData));

	return price;
};

const frontendToBackendFormatter = originalData => {
	let price = JSON.parse(JSON.stringify(originalData));
	if (price.marketPriceData.id !== undefined) {
		delete price.marketPriceData.id;
		return price.marketPriceData;
	} else {
		return price.marketPriceData;
	}
};

export default {
	state() {
		return {
			headers: ["Date", "520 g/l", "550 g/l", "570 g/l", "MG1"],
			tableData: [
				// [ '16/04/2021', 162, 194, 285, 432],
				// [ '15/04/2021', 142, 161, 311, 542],
				// [ '14/04/2021', 142, 171, 266, 420],
				// [ '12/04/2021', 156, 178, 250, 411],
			],
			loaded: false
		};
	},
	mutations: {
		updateMarketPriceList(state, { priceList }) {
			state.tableData = [];
			priceList.results.forEach(price =>
				state.tableData.push(backendToFrontendFormatter(price))
			);
			state.loaded = true;
		},
		updateMarketPriceDetails(state, { priceDetails }) {
			const index = state.tableData.findIndex(
				price => price.id == priceDetails.id
			);
			let price = backendToFrontendFormatter(priceDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, price);
			} else {
				state.tableData.push(price);
			}
		},
		deleteMarketPriceData(state, index) {
			state.tableData.splice(index, 1);
		}
	},
	actions: {
		fetchMarketPriceList({ commit }, { crop,start_date,end_date }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:(crop||start_date||end_date)?  api.marketPriceListURL.replace("{crop}", crop).replace("{start_date}",start_date).replace("{end_date}",end_date): 
						api.marketPriceListURL.replace("crop={crop}", "").replace("start_date={start_date}","").replace("end_date={end_date}","") ,
					method: "GET"
				})
					.then(priceList => {
						commit("updateMarketPriceList", { priceList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchMarketPriceDetails({ commit }, {priceID}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.marketPriceDetailsURL.replace("{id}", priceID),
					method: "GET"
				})
					.then(priceDetails => {
						commit("updateMarketPriceDetails", { priceDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setMarketPriceDetails({ commit }, {actionObject}) {
			let editPrice = actionObject.marketPriceData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editPrice
						? api.marketPriceDetailsURL.replace(
								"{id}",
								actionObject.marketPriceData.id)
						: api.marketPriceListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editPrice ? "PATCH" : "POST"
				})
					.then(priceDetails => {
						commit("updateMarketPriceDetails", { priceDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteMarketPriceData({ commit }, { id, index }) {
			let priceID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.marketPriceDetailsURL.replace("{id}", priceID),
					method: "DELETE"
				}).then(() => {
					commit("deleteMarketPriceData",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
	}
};
