import * as chartConfigs from '@/components/Charts/config';

export default {
	state() {
		return {
			tableHeaders: ["Analysis Name", "Details"],
			data: [
				{
					name: "Mark",
					details: "Otto",
				},
				{
					name: "Jacob",
					details: "Throton",
				},
				{
					name: "Larry",
					details: "Thebird",
				},
				{
					name: "Joe",
					details: "Dry",
				}
			],

			chartData: [{
				title: 'NDVI vs NDWI',
				subTitle: '',
				chartData: {
					labels: ['2018', '2019', '2020', '2021'],
					datasets: [{
						label: 'Quantity',
						data: [25, 20, 30, 22],
					}]
				},
				extraOptions: chartConfigs.blueChartOptions
			},
			{
				title: 'PH, Nutrients',
				subTitle: '',
				chartData: {
					labels: ['2018', '2019', '2020', '2021', '2022'],
					datasets: [{
						label: 'Amount',
						data: [25, 20, 30, 22, 17],
					}]
				},
				extraOptions: chartConfigs.blueChartOptions
			}]
		}
	}
}
