import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let nutrient = JSON.parse(JSON.stringify(originalData));

	return nutrient;
};

const frontendToBackendFormatter = originalData => {
	let nutrient = JSON.parse(JSON.stringify(originalData));
	if (nutrient.nutrientData.id !== undefined) {
		delete nutrient.nutrientData.crop;
		delete nutrient.nutrientData.id;
		return nutrient.nutrientData;
	} else {
		return nutrient.nutrientData;
	}
};

export default {
	state() {
		return {
			loaded: false,

			headers: ["Nutrient Name", "Sources"],

			tableData: [
				// {
				// 	nutrientName: " Nutrient A",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
				// 	sourceSelected: "One",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency A",
				// 	diseasePoison: "Poison A",
				// 	diseaseOther: "Others A"
				// },
				// {
				// 	nutrientName: " Nutrient S",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
				// 	sourceSelected: "Two",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency S",
				// 	diseasePoison: "Poison S",
				// 	diseaseOther: "Others S"
				// },
				// {
				// 	nutrientName: " Nutrient D",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
				// 	sourceSelected: "One",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency D",
				// 	diseasePoison: "Poison D",
				// 	diseaseOther: "Others D"
				// },
				// {
				// 	nutrientName: " Nutrient F",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
				// 	sourceSelected: "Three",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency F",
				// 	diseasePoison: "Poison F",
				// 	diseaseOther: "Others F"
				// },
				// {
				// 	nutrientName: " Nutrient G",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
				// 	sourceSelected: "Two",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency G",
				// 	diseasePoison: "Poison G",
				// 	diseaseOther: "Others G"
				// },
				// {
				// 	nutrientName: " Nutrient H",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
				// 	sourceSelected: "One",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	didiseaseDeficiency: "Deficiency H",
				// 	diseasePoison: "Poison H",
				// 	diseaseOther: "Others H"
				// },
				// {
				// 	nutrientName: " Nutrient J",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
				// 	sourceSelected: "One",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency J",
				// 	diseasePoison: "Poison J",
				// 	diseaseOther: "Others J"
				// },
				// {
				// 	nutrientName: " Nutrient K",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
				// 	sourceSelected: "One",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency K",
				// 	diseasePoison: "Poison K",
				// 	diseaseOther: "Others K"
				// },
				// {
				// 	nutrientName: " Nutrient L",
				// 	details:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
				// 	sourceSelected: "Two",
				// 	sourceDetails:
				// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
				// 	diseaseDeficiency: "Deficiency L",
				// 	diseasePoison: "Poison L",
				// 	diseaseOther: "Others L"
				// }
			]
		};
	},
	mutations: {
		updateNutrientsList(state, { nutrientsList }) {
			state.tableData = [];
			nutrientsList.results.forEach(nutrient =>
				state.tableData.push(backendToFrontendFormatter(nutrient))
			);
			state.loaded = true;
		},
		updateNutrientsDetails(state, { nutrientsDetails }) {
			const index = state.tableData.findIndex(
				nutrient => nutrient.id == nutrientsDetails.id
			);
			let nutrient = backendToFrontendFormatter(nutrientsDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, nutrient);
			} else {
				state.tableData.push(nutrient);
			}
		},
		deleteNutrientData(state, index) {
			state.tableData.splice(index, 1);
		}
	},
	actions: {
		fetchNutrientsList({ commit },{crop,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.nutrientsListURL.replace("{crop}",crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(nutrientsList => {
					commit('updateNutrientsList', { nutrientsList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchNutrientsDetails({ commit }, {nutrientID}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.nutrientDetailsURL.replace("{id}", nutrientID),
					method: "GET"
				})
					.then(nutrientsDetails => {
						commit("updateNutrientsDetails", { nutrientsDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setNutrientsDetails({ commit }, actionObject ) {
			let editNutrient = actionObject.nutrientData.id!= undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editNutrient
						? api.nutrientDetailsURL.replace(
								"{id}",
								actionObject.nutrientData.id)
						: api.nutrientsListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editNutrient ? "PATCH" : "POST"
				}).then(nutrientsDetails => {
					commit("updateNutrientsDetails", { nutrientsDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteNutrientData({ commit }, { id, index }) {
			let nutrientID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.nutrientDetailsURL.replace("{id}", nutrientID),
					method: "DELETE"
				}).then(() => {
					commit("deleteNutrientData",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
	}
};
