import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.name = data.input_name;
	delete data.input_name;

	data.type = data.input_type;
	delete data.input_type;

	return data;
};

const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.input_name = data.name;
	delete data.name;

	data.input_type = data.type;
	delete data.type;

	return data;
};

export default {
	state() {
		return {
			farmerID: -1,
			landID: -1,
			tableHeaders: ["Date", "Name", "Input Type", "Details"],
			agriInputList: [
				{
					date: "2021-06-17",
					name: "Mark",
					type: "Type1",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
				{
					date: "2021-06-16",
					name: "Jacob",
					type: "Type1",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
				{
					date: "2021-06-15",
					name: "Larry",
					type: "Type2",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
				{
					date: "2021-06-19",
					name: "Sam",
					type: "Type1",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
				{
					date: "2021-06-18",
					name: "Joe",
					type: "Type2",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
				{
					date: "2021-06-20",
					name: "Ben",
					type: "Type2",
					details:
						"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam.",
				},
			],
			types: ["type_1"],
		};
	},
	mutations: {
		updateAgriInput(state, { agriInputData }) {
			const index = state.agriInputList.findIndex(
				(a) => a.id == agriInputData.id
			);
			if (index === -1) {
				state.agriInputList.unshift(
					backendToFrontendFormatter(agriInputData)
				);
			} else {
				state.agriInputList.splice(
					index,
					1,
					backendToFrontendFormatter(agriInputData)
				);
			}
		},
		updateAgriInputs(state, { agriInputs, farmerID, landID }) {
			state.agriInputList = agriInputs.map((agriInput) =>
				backendToFrontendFormatter(agriInput)
			);
			state.farmerID = farmerID;
			state.landID = landID;
		},
		deleteAgriInput(state, agriInputID) {
			const index = state.agriInputList.findIndex(
				(a) => a.id == agriInputID
			);
			if (index !== -1) {
				state.agriInputList.splice(index, 1);
			}
		},
	},
	actions: {
		updateAgriInput({ commit }, { farmerID, agriInput }) {
			const editAgriInput = agriInput.id !== undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.agriInputListURL
						.replace("&start_date={startDate}", "")
						.replace("&end_date={endDate}", "")
						.replace("&search={searchQuery}", "")
						.replace("{user_id}", farmerID)
						.replace("?land={land_id}", "")
						.replace("&crop={crop}", "")
						.replace(
							"{id}/",
							editAgriInput ? `${agriInput.id}/` : ""
						),
					method: editAgriInput ? "PATCH" : "POST",
					data: frontendToBackendFormatter(agriInput),
				})
					.then((agriInputData) => {
						commit("updateAgriInput", { agriInputData, farmerID });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		deleteAgriInput({ commit, rootState }, { id }) {
			const farmerID = rootState.farmerData.agriInputData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteAgriInputURL
						.replace("{user_id}", farmerID)
						.replace("{id}", id),
					method: "DELETE",
				})
					.then(() => {
						commit("deleteAgriInput", id);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchAgriInputs(
			{ commit },
			{ farmerID, landID, crop, searchQuery, startDate, endDate }
		) {
			var token = localStorage.getItem("decoded-token");
			var date = true;
			if (startDate == null || endDate == null) {
				date = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: date
						? token === "admin"
							? api.agriInputListURL
									.replace("{user_id}", farmerID)
									.replace("{searchQuery}", searchQuery)
									.replace("{startDate}", startDate)
									.replace("{endDate}", endDate)
									.replace("{land_id}", landID)
									.replace("{crop}", crop)
									.replace("/{id}/", "")
							: api.executiveAgriInputURL
									.replace("{user_id}", farmerID)
									.replace("{searchQuery}", searchQuery)
									.replace("{startDate}", startDate)
									.replace("{endDate}", endDate)
									.replace("{land_id}", landID)
									.replace("{crop}", crop)
									.replace("/{id}/", "")
						: token === "admin"
						? api.agriInputListURL
								.replace("{user_id}", farmerID)
								.replace("{searchQuery}", searchQuery)
								.replace("&start_date={startDate}", "")
								.replace("&end_date={endDate}", "")
								.replace("{land_id}", landID)
								.replace("{crop}", crop)
								.replace("/{id}/", "")
						: api.executiveAgriInputURL
								.replace("{user_id}", farmerID)
								.replace("{searchQuery}", searchQuery)
								.replace("&start_date={startDate}", "")
								.replace("&end_date={endDate}", "")
								.replace("{land_id}", landID)
								.replace("{crop}", crop)
								.replace("/{id}/", ""),
					method: "GET",
				})
					.then((agriInputData) => {
						commit("updateAgriInputs", {
							agriInputs: agriInputData.results,
							farmerID,
							landID,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
