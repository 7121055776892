export default {
	historyData: [{
			date: "Loading...",
			price: "0",
			unit: "g",
		},
	],
	predictionData: [{
			date: "Loading...",
			price: "0",
			unit: "g",
		},
	],
	loaded: false,
};
