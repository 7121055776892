import api from "@/api";

const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
        return {
            cardData: {},
			taskList: [],
			attendanceList: [
				
			],
			farmerCount:null
			
		};
	},
	mutations: {
		updateDashboardList(state, dashboardData) {
			state.cardData =backendToFrontendFormatter(dashboardData)
        },
        updateDashboardTaskList(state, dashboardTaskData) {
            state.taskList = [];
            dashboardTaskData.forEach(item => state.taskList.push(backendToFrontendFormatter(item)));
            
        },
        approveTask(state, selectedItem) {
            const index = state.taskList.indexOf(selectedItem)
            state.taskList.splice(index, 1)
        },
		declineTask(state, index) {
			state.taskList.splice(index, 1,)
		},

		//Executive Dashboard
		updateAttendanceList(state, attendanceListData) {
			state.attendanceList = [];
			attendanceListData.forEach(item => state.attendanceList.push(backendToFrontendFormatter(item)));
		},
		updateFarmerCount(state, farmerCount) {
			state.farmerCount=farmerCount.farmers
		}
	},
	actions: {
		fetchDashboardData({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.dashboardURL,
					method: "GET"
				}).then(dashboardData => {
					commit('updateDashboardList', dashboardData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		fetchExeFarmerCount({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.executiveFarmerCount,
					method: "GET"
				}).then(farmerCount => {
					commit('updateFarmerCount', farmerCount);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
        },
        fetchDashboardTaskList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.dashboardTaskListURL,
					method: "GET"
                }).then(dashboardTaskData => {
					commit('updateDashboardTaskList', dashboardTaskData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
        },
        approveTask({ commit }, { taskData,selectedItem}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.dashboardTaskDetailsURL.replace("{id}",selectedItem.id),
                    method: "PATCH",
                    data:frontendToBackendFormatter(taskData)
                }).then(taskDetails => {
                    commit('approveTask', { taskDetails, selectedItem });
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		declineTask({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.dashboardTaskDetailsURL.replace("{id}",id),
					method: 'DELETE',
				}).then(() => {
					commit('declineTask', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},


		// Executive Dashboard

		fetchExecutiveAttendanceList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveAttendanceListURL,
					method: "GET"
				}).then(attendanceListData => {
					commit('updateAttendanceList', attendanceListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		}

	}
};