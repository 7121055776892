import api from "@/api";
import axios from "axios";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	

	// if (data.map(('farm_data_type')) {
	// 	delete data.id;
	// 	delete data.farm_data_type;
	// 	delete data.land;
	// }
	return data;
};

export default {
	state() {
		return {
			varietyTableHeaders: ["Variety", "Count", "Crop age", "Supporting Crop", "Crop Height"],
			miscLandFiles: [],
			miscCropFiles: [],
			satelliteImageFile:[],
			localTableData: [{
				name: "Pepper",
				count: "99",
				age: "2",
				support: "Yes",
				height: "55"
			}],

			improvedTableData: [{
				name: "Impro1",
				count: "23",
				age: "1",
				support: "N/A",
				height: "11"
			}, {
				name: "Impro2",
				count: "3",
				age: "3",
				support: "Crop support",
				height: "34"
			}],

			microTableHeaders: ["Indicator", "Quantity", "Behaviour"],
			microTableData: [
				
			],

			intercropTableHeaders: ["Intercroppings"],
			intercropTableData: ["Intercrop 1", "Intercrop 2"],

			dataTables: [
				{
					name: "Water Info",
					headers: ["Details" ],
					data: [
						["Water source", ],
						["Water quality", ],
						["Irrigation management", ],
						["Agriculture water management", ],
						["Water accouting and auditing", ],
					]
				}, {
					name: "Planting Methods",
					headers: ["Details"],
					data: [
						["Plant preparation methods", ],
						["Nursery info"],
						["Properties of pit"],
						["Other info"],
					]
				}, {
					name: "Farm Practices",
					headers: ["Details" ],
					data: [
						// ["Preharvest land preparation", ],
						// ["Fertilizers applied & practices"],
						// ["Planting practices"],
						// ["Soil fertility practices", ],
						// ["Harvesting method and cost", ],
						// ["Fertilizer & pesticide application", ],
						// ["Water application", ],
						// ["Storage practices", ],
						// ["Crop processing and storage", ],
						// ["Cost of processing", ],
						// ["Quality", ],
					]
				}, {
					name: "Soil and Land Info",
					headers: [ "Details"],
					data: [
						// ["Soil type"],
						// ["Land area", ],
						// ["Geographic data & mapping"],
						// ["Previous use of land"],
						// ["Soil env change impact"],
						// ["Terrain landscape & mapping"],
						// ["Water content storage"],
						// ["Soil pollution and depositions"],
						// ["Soil fertility methods"],
					]
				},
				// {
				// 	name: "Meteorological Data",
				// 	headers: ["Data", "Details"],
				// 	data: [
				// 		// ["Solar radiation"],
				// 		// ["Climatic conditions"],
				// 		// ["Dew point"],
				// 		// ["Current weather"],
				// 		// ["Precipitation amount"],
				// 		// ["Rainfall"],
				// 		// ["Temperature data"],
				// 		// ["Relative humidity"],
				// 		// ["Wind speed and direction"],
				// 		// ["Sea level pressure"],
				// 		// ["Clouds observed"],
				// 		// ["Visiblity conditions"],
				// 		// ["Windros data"],
				// 	]
				// },
				{
					name: "Satellite Data",
					headers: ["Data","Details"],
					data: [
						["Monitoring"],
						["Biomass estimation"],
						["NDWI vs NDWI"],
						["Misc"],
					]
				},
			],
		}

	},
	mutations: {
		updateLocalVarietiesList(state, { localVarietyData }) {
			state.localTableData = []
			localVarietyData.forEach(item => state.localTableData.push(backendToFrontendFormatter(item)))
		},
		updateLocalVarietiesDetails(state, { localVarietyData }) {
			const index = state.localTableData.findIndex(
				data => data.id == localVarietyData.id
			);
			let item = backendToFrontendFormatter(localVarietyData);
			if (index != -1) {
				state.localTableData.splice(index, 1, item);
			} else {
				state.localTableData.push(item);
			}
		},
		deleteLocalCrop(state, Index) {
			state.localTableData.splice(Index, 1)
		},
		updateImprVarietiesList(state, { improvedVarietyData }) {
			state.improvedTableData = []
			improvedVarietyData.forEach(item => state.improvedTableData.push(backendToFrontendFormatter(item)))
		},
		updateImprVarietiesDetails(state, { improvedVarietyData }) {
			const index = state.improvedTableData.findIndex(
				data => data.id == improvedVarietyData.id
			);
			let item = backendToFrontendFormatter(improvedVarietyData);
			if (index != -1) {
				state.improvedTableData.splice(index, 1, item);
			} else {
				state.improvedTableData.push(item);
			}
		},
		deleteImprovedCrop(state, Index) {
			state.improvedTableData.splice(Index, 1)
		},
		updateIntercroppingsList(state, { inter_croppingData }) {
			state.intercropTableData = []
			inter_croppingData.forEach(item => state.intercropTableData.push(backendToFrontendFormatter(item)))
		},
		updateInterCroppingDetails(state, { interCroppingData }) {
			const index = state.intercropTableData.findIndex(item => item.id == interCroppingData.id);
			let item = backendToFrontendFormatter(interCroppingData);
			if (index != -1) {
				state.intercropTableData.splice(index, 1, item);
			} else {
				state.intercropTableData.push(item);
			}
		},
		deleteIntercroping(state, Index) {
			state.intercropTableData.splice(Index, 1)
		},
		updateSoilReportList(state, { soilReportData }) {
			state.microTableData = []
			soilReportData.forEach(item => state.microTableData.push(backendToFrontendFormatter(item)))
		},
		updatesoilReportDetails(state, { soilReportData }) {
			const Index = state.microTableData.findIndex(item => item.id == soilReportData.id);
			let reportData = backendToFrontendFormatter(soilReportData);
			if (Index != -1) {
				state.microTableData.splice(Index, 1, reportData);
			} else {
				state.microTableData.push(soilReportData);
			}

		},
		//Misc
		updateMiscellaneousList(state, { miscellaneousData,tableIndex }) {
			state.dataTables[tableIndex].data = [];
			miscellaneousData.forEach(item => state.dataTables[tableIndex].data.push(backendToFrontendFormatter(item)));
			
			
		},
		updateMiscellaneosDetails(state, { miscellaneousData ,index}) {
			const Index = state.dataTables[index].data .findIndex(item => item.id == miscellaneousData.id);
			let miscData = backendToFrontendFormatter(miscellaneousData);
			if (Index != -1) {
				state.dataTables[index].data .splice(Index, 1, miscData);
			} 

		},
		updateMiscellaneousCropFile(state, miscellaneousData) {
			state.miscCropFiles = [];
			miscellaneousData.forEach(item=>state.miscCropFiles.push(backendToFrontendFormatter(item)))
			
		},
		updateMiscellaneousLandFile(state, miscellaneousData) {
			state.miscLandFiles = [];
			miscellaneousData.forEach(item=>state.miscLandFiles.push(backendToFrontendFormatter(item)))
			
		},
		updateSatelliteFile(state, satelliteFile) {
			state.satelliteImageFile = [];
			satelliteFile.forEach(item=>state.satelliteImageFile.push(backendToFrontendFormatter(item)))
			
		},
		deleteLandMiscFile(state, index) {
			state.miscLandFiles.splice(index,1)
			
		},
		deleteCropMiscFile(state, index) {
			state.miscCropFiles.splice(index,1)
			
		},
		deleteSatelliteFile(state,index) {
			state.satelliteImageFile.splice(index,1)
		}
	},
	actions: {
		fetchLocalVarietiesList({ commit }, { farmerID, crop, land }) {
			var token = localStorage.getItem('decoded-token');

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.farmLocalVarietiesListURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land):api.executiveLocalVarietiesURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land),
					method: "GET"
				}).then(localVarietyData => {
					commit('updateLocalVarietiesList', { localVarietyData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchLocalVarietyDetails({ commit }, { farmerID, itemID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmLocalVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				}).then(localVarietyData => {
					commit("updateLocalVarietiesDetails", { localVarietyData });
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		updateLocalVarietyDetails({ commit }, { farmerID, actionObject }) {
			const editlocalCrop = actionObject.localCrop.id !== undefined;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editlocalCrop
						? api.farmLocalVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.localCrop.id).replace("?crop={crop}",'').replace("&land={land}","")
						: api.farmLocalVarietiesListURL.replace("{id}", farmerID).replace("?crop={crop}",'').replace("&land={land}",""),
					method: editlocalCrop ? "PATCH" : "POST",
					data: actionObject.localCrop
				}).then(localVarietyData => {
					commit('updateLocalVarietiesDetails', { localVarietyData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		deleteLocalCrop({ commit }, { index, id, miscData: { farmerID } }) {
			let Index = index;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmLocalVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit("deleteLocalCrop", Index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		//improved-varient
		fetchImprovedVarietiesList({ commit }, { farmerID, crop, land }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.farmImprovedVarietiesListURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land):api.executiveImprovedVarietiesURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land),
					method: "GET"
				}).then(improvedVarietyData => {
					commit('updateImprVarietiesList', { improvedVarietyData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchImprovedVarietyDetails({ commit }, { farmerID, itemID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmImprovedVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				}).then(improvedVarietyData => {
					commit("updateImprVarietiesDetails", { improvedVarietyData });
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		updateImprovedVarietyDetails({ commit }, { farmerID, actionObject }) {
			const editImprovedCrop = actionObject.improvedCrop.id !== undefined;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editImprovedCrop
						? api.farmImprovedVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.improvedCrop.id).replace("?crop={crop}",'').replace("&land={land}","")
						: api.farmImprovedVarietiesListURL.replace("{id}", farmerID).replace("?crop={crop}",'').replace("&land={land}",""),
					method: editImprovedCrop ? "PATCH" : "POST",
					data: actionObject.improvedCrop
				}).then(improvedVarietyData => {
					commit('updateImprVarietiesDetails', { improvedVarietyData, farmerID });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		deleteImprovedCrop({ commit }, { index, id, miscData: { farmerID } }) {
			let Index = index;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmImprovedVarietyDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit("deleteImprovedCrop", Index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		//inter-cropping
		// fetchIntercroppingList({ commit }, farmerID) {
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: api.farmIntercroppingsListURL.replace("{id}", farmerID),
		// 			method: "GET"
		// 		}).then(inter_croppingData => {
		// 			commit('updateIntercroppingsList', { inter_croppingData });
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	})
		// },
		// fetchIntercroppingDetails({ commit }, { farmerID, itemID }) {
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: api.farmIntercroppingsDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
		// 			method: "GET"
		// 		}).then(interCroppingData => {
		// 			commit("updateInterCroppingDetails", { interCroppingData });
		// 			resolve();
		// 		})
		// 			.catch(errMsg => reject(errMsg));
		// 	});
		// },
		// updateIntercroppingDetails({ commit }, { farmerID, actionObject }) {
		// 	const editInterCrop = actionObject.interCropData.id !== undefined;
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: editInterCrop
		// 				? api.farmIntercroppingsDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.interCropData.id)
		// 				: api.farmIntercroppingsListURL.replace("{id}", farmerID),
		// 			method: editInterCrop ? "PATCH" : "POST",
		// 			data: actionObject.interCropData
		// 		}).then(interCroppingData => {
		// 			commit('updateInterCroppingDetails', { interCroppingData });
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	})
		// },
		// deleteInterCropping({ commit }, { index, id, miscData: { farmerID } }) {
		// 	let Index = index;
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: api.farmIntercroppingsDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
		// 			method: "DELETE"
		// 		}).then(() => {
		// 			commit("deleteIntercroping", Index);
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	});
		// },
		//soil-report
		fetchSoilReportList({ commit }, { farmerID, land }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:token==='admin' ? api.farmSoilReportListURL.replace("{id}", farmerID).replace("{land}",land):api.executiveSoilReportURL.replace("{id}", farmerID).replace("{land}",land),
					method: "GET"
				}).then(soilReportData => {
					commit('updateSoilReportList', { soilReportData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchSoilReportDetails({ commit }, { farmerID, itemID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmSoilReportDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				}).then(soilReportData => {
					commit("updatesoilReportDetails", { soilReportData });
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		updateSoilReportDetails({ commit }, { farmerID, actionObject }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.farmSoilReportDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.soilData.id),
					method: "PATCH",
					data: actionObject.soilData
				}).then(soilReportData => {
					commit('updatesoilReportDetails', { soilReportData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		// miscellaneous 
		fetchMiscellaneousList({ commit }, { farmerID, tableIndex, crop, land }) {
			var token = localStorage.getItem('decoded-token');
			var dataType = 'water_info';
			if (tableIndex == 1) {
				dataType = 'planting_methods';
			} else if (tableIndex == 2) {
				dataType = 'farm_practices';
			} else if (tableIndex == 3) {
				dataType='soil_and_land_info'
			}
			
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: (dataType == 'soil_and_land_info' || dataType == 'water_info') ? ( token === 'admin' ? api.farmMiscellaneousLandListURL.replace("{id}", farmerID).replace('{data_type}', dataType).replace("{land}", land):api.executiveLandMiscellaneousURL.replace("{id}", farmerID).replace('{data_type}', dataType).replace("{land}", land)) :
						(token === 'admin' ? api.farmMiscellaneousCropListURL.replace("{id}", farmerID).replace('{data_type}', dataType).replace("{crop}", crop).replace("{land}", land):api.executiveCropMiscellaneousURL.replace("{id}", farmerID).replace('{data_type}', dataType).replace("{crop}", crop).replace("{land}", land)) 
						,
					method: "GET"
				}).then(miscellaneousData => {
					commit('updateMiscellaneousList', { miscellaneousData,tableIndex });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchSatelliteData({ commit }, { farmerID, tableIndex, land }) {
			var token = localStorage.getItem('decoded-token');			
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  ( token === 'admin' ? api.farmSatelliteListURL.replace("{id}", farmerID).replace("{land}", land):api.executiveSatelliteListURL.replace("{id}", farmerID).replace("{land}", land)),
					method: "GET"
				}).then(miscellaneousData => {
					commit('updateMiscellaneousList', { miscellaneousData,tableIndex });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchMiscellaneousDetails({ commit }, { farmerID, itemID, index }) {
			var dataType = 'water_info';
			if (index == 1) {
				dataType = 'planting_methods';
			} else if (index == 2) {
				dataType = 'farm_practices';
			} else if (index == 3) {
				dataType='soil_and_land_info'
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: (dataType == 'planting_methods' || dataType == 'farm_practices') ? api.farmMiscellaneousCropDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID):
					api.farmMiscellaneousLandDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				}).then(miscellaneousData => {
					commit("updateMiscellaneosDetails", { miscellaneousData ,index});
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchSatelliteDetails({ commit }, { farmerID, itemID, index }) {

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.farmSatelliteDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),					
					method: "PUT"
				}).then(miscellaneousData => {
					commit("updateMiscellaneosDetails", { miscellaneousData ,index});
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		updateMiscellaneousDetails({ commit }, { farmerID, actionObject, index }) {
			var dataType = 'water_info';
			if (index == 1) {
				dataType = 'planting_methods';
			} else if (index == 2) {
				dataType = 'farm_practices';
			} else if (index == 3) {
				dataType='soil_and_land_info'
			}

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: (dataType == 'planting_methods' || dataType == 'farm_practices') ? api.farmMiscellaneousCropDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.id):
					api.farmMiscellaneousLandDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.id),					
					method: "PATCH",
					data: actionObject
				}).then(miscellaneousData => {
					commit('updateMiscellaneosDetails', { miscellaneousData,index });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		updateSatelliteDetails({ commit }, { farmerID, actionObject, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:api.farmSatelliteDetailsURL.replace("{user_id}", farmerID).replace("{id}", actionObject.id),									
					method: "PATCH",
					data: actionObject
				}).then(miscellaneousData => {
					commit('updateMiscellaneosDetails', { miscellaneousData,index });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		uploadCropMiscFile({ commit }, { miscFile}) {
			return new Promise((resolve, reject) => {
				axios.post( api.uploadCropMiscFile,
					miscFile,{
						headers: {
							'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
						}
					}
				).then(miscellaneousData => {
					commit("updateMiscellaneousLandFil", miscellaneousData);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		uploadLandMiscFile({ commit }, { miscFile }) {
			return new Promise((resolve, reject) => {
				axios.post(api.uploadLandMiscFile,
					miscFile,{
						headers: {
							'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
						}
					}
				).then(miscellaneousData => {
					commit("updateMiscellaneousLandFil", miscellaneousData);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		uploadSatelliteFile({ commit }, { miscFile }) {
			return new Promise((resolve, reject) => {
				axios.post(api.uploadSatelliteFile,
					miscFile,{
						headers: {
							'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
						}
					}
				).then(miscellaneousData => {
					commit("updateMiscellaneousLandFil", miscellaneousData);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchMiscellaneousCropFile({ commit }, { farmerID, dataType }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.fetchMiscCropFileListURL.replace("{id}",farmerID).replace("{farm_data_type}",dataType):api.executiveCropMiscellaneousFileURL.replace("{id}",farmerID).replace("{farm_data_type}",dataType),
					method: "GET"
				}).then(miscellaneousData => {
					commit("updateMiscellaneousCropFile",  miscellaneousData );
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchMiscellaneousLandFile({ commit }, { farmerID, dataType }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.fetchMiscLandFileListURL.replace("{id}",farmerID).replace("{farm_data_type}",dataType):api.executiveLandMiscellaneousFileURL.replace("{id}",farmerID).replace("{farm_data_type}",dataType),
					method: "GET"
				}).then(miscellaneousData => {
					commit("updateMiscellaneousLandFile", miscellaneousData );
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchSatelliteFile({ commit },  farmerID  ) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.fetchSatelliteFile.replace("{id}",farmerID):api.executiveSatelliteFileURL.replace("{id}",farmerID),
					method: "GET"
				}).then(satelliteFile => {
					commit("updateSatelliteFile", satelliteFile );
					resolve();
				})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteLandMiscFile({ commit }, { index, id }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteLandFilURL.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit("deleteLandMiscFile", index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteCropMiscFile({ commit }, { index, id}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteCropFilURL.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit("deleteCropMiscFile", index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteSatelliteFile({commit},{index,id}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.deleteSatelliteFilURL.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit("deleteSatelliteFile", index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
		
		
		


	}
};
