export default {
	historyData: [{
			date: "Loading...",
			title: "Loading...",
		}
	],
	upcomingData: [{
			date: "Loading...",
			title: "Loading...",
		}
	],
	loaded: false,
};
