import api from "@/api";
import axios from "axios";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.date = data.added_date;
	delete data.added_date;

	return data;
};

/* const frontendtoBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));

	data.added_date = data.date;
	delete data.date;

	return data;
}; */

export default {
	state() {
		return {
			farmerID: -1,
			landID: -1,
			tableHeaders: ["Date", "Recommendation", "Section"],
			recommendations: [
				// {
				// 	date: "2021-06-17",
				// 	recommendation: "Water more",
				// 	section: "crop",
				// 	details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
				// },
				// {
				// 	date: "2021-06-15",
				// 	recommendation: "Water less",
				// 	section: "soil",
				// 	details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
				// },
				// {
				// 	date: "2021-06-12",
				// 	recommendation: "Water more",
				// 	section: "crop",
				// 	details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
				// },
			],
			sectionList: ["crop", "soil",'general']
		}
	},
	mutations: {
		updateRecommendations(state, { recommendations, farmerID }) {
			state.recommendations = recommendations.map(r => backendToFrontendFormatter(r));
			state.farmerID = farmerID;
		},
		updateRecommendationDetails(state, { recommendationData }) {
			const index = state.recommendations.findIndex(
				data => data.id == recommendationData.id
			);
			let item = backendToFrontendFormatter(recommendationData);
			if (index != -1) {
				state.recommendations.splice(index, 1, item);
			} else {
				state.recommendations.push(item);
			}
		},
		deleteRecommendation(state, recommendationID) {
			const index = state.recommendations.findIndex(r => r.id == recommendationID);
			if (index !== -1) {
				state.recommendations.splice(index, 1);
			}
		}
	},
	actions: {
		setRecommendation({ commit },{ farmerID, actionObject }) {
			const editRecommendation = actionObject.recommData.get("id") !== "undefined";
			if(!editRecommendation){
				return new Promise((resolve, reject) => {
					axios.post(api.recommendationListURL
						.replace("?land={land_id}", "")
						.replace("{user_id}", farmerID)
						.replace("{id}/", "").replace("&search={searchQuery}","").replace("&start_date={startDate}","").replace("&end_date={endDate}","").replace("&land={land}","").replace("&crop={crop}",""),
						actionObject.recommData,{
							headers: {
								'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
							}
						}
					).then(recommendationData => {
						commit("updateRecommendationDetails", { recommendationData });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
			else {
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url:api.recommendationDetailsURL
						.replace("{user_id}", farmerID)
						.replace("{id}", actionObject.recommData.get("id")),
						data:actionObject.recommData,
						method:"PATCH"
					}).then(recommendationData => {
						commit("updateRecommendationDetails", { recommendationData ,farmerID});
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
			}
		},
		// setRecommendation({ commit }, { farmerID, actionObject }) {
		// 	const editRecommendation = actionObject.recommData.get("id") !== "undefined";

		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: editRecommendation
		// 				? api.recommendationDetailsURL
		// 						.replace("{user_id}", farmerID)
		// 						.replace("{id}", actionObject.recommData.get("id"))
		// 				: api.recommendationListURL
		// 						.replace("?land={land_id}", "")
		// 						.replace("{user_id}", farmerID)
		// 						.replace("{id}/", ""),
		// 			method: editRecommendation ? "PATCH" : "POST",
		// 			data: actionObject.recommData
		// 		}).then(recommendationData => {
		// 			commit('updateRecommendationDetails', { recommendationData, farmerID });
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	})
		// },
		fetchRecommendations({ commit }, { farmerID, landID, startDate, endDate, searchQuery, crop }) {
			var token = localStorage.getItem('decoded-token');
			var date = true;
			if (startDate == null || endDate == null||searchQuery=="") {
				date = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: date? (token === 'admin' ? api.recommendationListURL.replace("{land_id}", landID).replace("{user_id}", farmerID).replace("/{id}/", "").replace("{searchQuery}", searchQuery).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{crop}",crop):api.executiveRecommendationListURL.replace("{land_id}", landID).replace("{user_id}", farmerID).replace("/{id}/", "").replace("{searchQuery}", searchQuery).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{crop}",crop))  :
					(token === 'admin' ? api.recommendationListURL.replace("{land_id}", landID).replace("{user_id}", farmerID).replace("/{id}/", "").replace("&search={searchQuery}", "").replace("{crop}",crop) .replace("&start_date={startDate}","").replace("&end_date={endDate}",""):api.executiveRecommendationListURL.replace("{land_id}", landID).replace("{user_id}", farmerID).replace("/{id}/", "").replace("&search={searchQuery}", "").replace("{crop}",crop) .replace("&start_date={startDate}","").replace("&end_date={endDate}","")),
					method: "GET"
				}).then(recommendationData => {
					commit('updateRecommendations', { recommendations: recommendationData.results, 
						farmerID, landID });
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
		fetchRecommendationDetails({ commit }, { farmerID, itemID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.recommendationDetailsURL
							.replace("{user_id}", farmerID)
							.replace("{id}", itemID),
					method: "GET"
				}).then(recommendationData => {
					commit("updateRecommendationDetails", { recommendationData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteRecommendation({ commit, rootState }, { id }) {
			const farmerID = rootState.farmerData.recommendationData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.recommendationDetailsURL.replace("{user_id}", farmerID)
						.replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteRecommendation', id);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		},
	}
}