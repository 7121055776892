import expertsStore from "./Crops/ExpertsStore.js";
import cropVarietiesStore from "./Crops/CropVarietiesStore.js";
import farmPracticesStore from "./Crops/FarmPracticesStore.js";
import diseasesStore from "./Crops/DiseasesStore.js";
import nutrientsStore from "./Crops/NutrientsStore.js";
import marketPriceStore from "./Crops/MarketPriceStore.js";
import fertilizerStore from "./Crops/FertilizersStore.js";
import pesticideStore from "./Crops/PesticidesStore.js";
import institutionalAdvisoriesStore from "./Crops/InstitutionalAdvisoriesStore.js";
import pestsAndAttacksStore from "./Crops/PestsAndAttacksStore.js";
import govtSchemesStore from "./Crops/GovtSchemesStore.js";
import geographicDataStore from "./Crops/GeographicDataStore.js";
import waterManagementStore from "./Crops/WaterManagementStore.js";
import soilReportsStore from "./Crops/SoilReportsStore.js";
import satelliteImagesStore from "./Crops/SatelliteImagesStore.js";
import meteorologicalDataStore from "./Crops/MeteorologicalDataStore.js";
import cropCalendarStore from "./Crops/CropCalendarStore.js";
import miscStore from "./Crops/MiscStore.js";
import api from "../api.js";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

export default {
	state() {
		return {
			cropLists: []
		};
	},
	modules: {
		experts: expertsStore,
		cropVarieties: cropVarietiesStore,
		farmPractices: farmPracticesStore,
		diseases: diseasesStore,
		nutrients: nutrientsStore,
		marketPrice: marketPriceStore,
		fertilizers: fertilizerStore,
		pesticides: pesticideStore,
		institutionalAdvisories: institutionalAdvisoriesStore,
		pestsAndAttacks: pestsAndAttacksStore,
		govtSchemes: govtSchemesStore,
		geographicData: geographicDataStore,
		waterManagement: waterManagementStore,
		soilReports: soilReportsStore,
		satelliteImages: satelliteImagesStore,
		meteorologicalData: meteorologicalDataStore,
		cropCalendar: cropCalendarStore,
		misc: miscStore
	},
	mutations: {
		updateCropList(state, { cropList }) {
			state.cropLists = [];
			cropList.forEach(item => state.cropLists.push(backendToFrontendFormatter(item)));
		}
		
	},
	actions: {
		fetchCropList({commit}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropListURL,
					method:"GET"
				}).then(cropList => {	
					commit("updateCropList", { cropList });
					resolve();
				}).catch(errMsg=>reject(errMsg))
			})
		}
	}
};
