export default {
	tags: ['Core team', 'Beta intern team', 'Alpha intern team', 'Executives'],
	events: [{
			title: 'Meeting',
			start: '2020-12-12' + 'T12:00:00', // 12 PM
			end:   '2020-12-12' + 'T13:00:00', // 1  PM
			tags: ['Beta intern team'],
		},
		{
			title: 'Alpha Launch',
			start: '2021-01-05',
			end: '2021-01-06',
			tags: ['Core team', 'Beta intern team', 'Alpha intern team'],
		}
	]
}
