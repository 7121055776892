import api from "@/api";
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

// const frontendToBackendFormatter = originalData => {
// 	const data = JSON.parse(JSON.stringify(originalData));
// 	return data;
// };
export default {
    state() {
		return {
            images: [],
            farmerCropList: [],
            cropPart: [],
            cropVariety:[]
		}
    },
	mutations: {
		updateFarmerImages(state, imageData) {
			state.images = [];
			imageData.forEach(item => state.images.push(backendToFrontendFormatter(item)));
        },
        updateCropLists(state, data) {
            state.farmerCropList = [];
			data.forEach(item => state.farmerCropList.push(backendToFrontendFormatter(item)));
        },
        updateCropPartList(state,cropPartList) {
            state.cropPart = [];
			cropPartList.forEach(item => state.cropPart.push(backendToFrontendFormatter(item))); 
        },
        updateCropVarietyList(state, cropVarietyList) {
            state.cropVariety = [];
			cropVarietyList.forEach(item => state.cropVariety.push(backendToFrontendFormatter(item))); 
        }
	},
	actions: {
        fetchFarmerImageList({ commit }, { farmerID ,crop,cropVariety,cropPart}) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveFarmerImagesURL.replace("{id}",farmerID).replace("{crop}" ,crop).replace("{crop_variety}" ,cropVariety).replace("{crop_part}" ,cropPart),
					method: "GET"
                }).then(imageData => {
					commit('updateFarmerImages', imageData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        fetchFarmerCropListData({ commit }, farmerID) {
            return new Promise((resolve, reject) => 
            api.actionHandler({
                url: api.executiveFarmerCropListURL.replace("{id}",farmerID),
                method: "GET"
            }).then(data => {
                commit('updateCropLists', data);
                resolve();
            }).catch(errMsg => reject(errMsg))
        );
        },
        fetchFarmerCropPartList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveFarmerCropPartURL,
					method: "GET"
                }).then(cropPartList => {
					commit('updateCropPartList', cropPartList);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
        },
        fetchFarmerCropVarietyList({ commit } , cropID) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.executiveCropVarietyListURL.replace("{id}",cropID),
					method: "GET"
                }).then(cropVarietyList => {
					commit('updateCropVarietyList', cropVarietyList);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
	}
}