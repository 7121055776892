import axios from 'axios';
import api from '@/api';
import jwt_decode from "jwt-decode";

function getItem(itemName, defaultValue) {
	let localItem = localStorage.getItem(itemName);
	if (localItem != undefined) {
		return localItem;
	} else {
		return defaultValue;
	}
}

export default {
	state: () => ({
		username: getItem('username', 'Username'),
		token: getItem('admin-token'),
		decoded_token:getItem('decoded-token'),
		userID: getItem('user-id', -1),
		profilePicture: getItem('profile-picture', 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'),
	}),
	mutations: {
		login(state, { token, username, userID,decoded }) {
			state.token = token;
			state.username = username;
			state.userID = userID;
			state.decoded_token = decoded.user_type;
		},	
		logout(state) {
			state.token = undefined;
			state.username = undefined;
			state.userID = undefined;
			state.decoded_token = undefined;
			localStorage.removeItem('admin-token')
			localStorage.removeItem('decoded-token')
			localStorage.removeItem('username')
			localStorage.removeItem('user-id')
			

			delete axios.defaults.headers.common['Authorization'];
		}
	},
	actions: {
		login({ commit }, userData) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.loginURL,
					data: userData,
					method: 'POST',
				}).then(loginData => {
					const token = loginData.token;
					const decoded = jwt_decode(token);
					const username = loginData.user.username;
					const userID = loginData.user.id;
					localStorage.setItem('admin-token', token);
					localStorage.setItem('decoded-token', decoded.user_type);
					localStorage.setItem('username', username);
					localStorage.setItem('user-id', userID);
					axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
					commit('login', { token, username, userID,decoded });
					// commit('updateProfile', loginData.user);					
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		logout({ getters }) {
			return new Promise((resolve, reject) => {
				if (!getters.isAuthenticated) {
					resolve();
				} else {
					api.actionHandler({
						url: api.logoutURL,
						method: 'POST',
					}).then(() => resolve())
						.catch(errMsg => reject(errMsg));
				}
			});
		}
	},
	getters: {
		isAuthenticated(state) {
			return !!state.token;
		}
	},
}
