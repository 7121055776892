import api from "@/api";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));	
	return data;
};



export default {
	state() {
		return {
			farmerReport:[]
		};
	},
	mutations: {
		updateFarmerReport(state, { farmerReportData } ) {
			state.farmerReport = [];
			farmerReportData.forEach(item =>
				state.farmerReport.push(backendToFrontendFormatter(item))
			);
		},

	},
	actions: {
		fetchFarmerReportData({ commit },farmerID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmerReportURL.replace("{id}",farmerID),
					method: "GET"
				}).then(farmerReportData => {
					commit("updateFarmerReport", { farmerReportData } );
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},

	}
};
