import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let pesticide = JSON.parse(JSON.stringify(originalData));

	return pesticide;
};

const frontendToBackendFormatter = originalData => {
	let pesticide = JSON.parse(JSON.stringify(originalData));
	if(pesticide.pestData.id!==undefined){
		delete pesticide.pestData.crop
		delete pesticide.pestData.id
		return pesticide.pestData;
	} else {
		return pesticide.pestData
	}

	
};

export default {
	state() {
		return {
			headers: ['Name', 'Reason for application', 'Method of application', 'Details'],
            tableData: [
        // [ 'ENDSLH', 'Spray', '• MOA 1', '• Sdijsd sum dolor sit amet ksks skdjs djs dis d\n• Sdisdsd sid wdw. Sdosdoj\n• Sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
        // [ 'PNN3000', 'Spray', '• MOA 1', '• Oh lorem si offer sit amet ksks\n• Skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
    ],
    loaded: false,
		};
	},
    mutations: {
        updatePesticideList(state, { pesticideList }) {
			state.tableData = [];
			pesticideList.results.forEach(pesticide => state.tableData.push(backendToFrontendFormatter(pesticide)))
			state.loaded = true;
		},
		updatePesticideDetails(state, { pesticideDetails }) {
			const index = state.tableData.findIndex(pesticide => pesticide.id == pesticideDetails.id)
			let pesticide = backendToFrontendFormatter(pesticideDetails);
			if(index != -1) {
				state.tableData.splice(index, 1, pesticide);
			} else {
				state.tableData.push(pesticide);
			}
		},
		deletePesticide(state, index) {
			state.tableData.splice(index,1)
		}
    },
    actions: {
        fetchPesticideList({ commit },{crop,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPesticideListURL.replace("{crop}",crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(pesticideList => {
					commit('updatePesticideList', { pesticideList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchPesticideDetails({ commit }, pesticideID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPesticideDetailsURL.replace("{id}", pesticideID),
					method: "GET"
				}).then(pesticideDetails => {
					commit("updatePesticideDetails", { pesticideDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setPesticideDetails({ commit },  actionObject ) {
			let editPesticide = actionObject.pestData.id!= undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editPesticide ? api.fetchPesticideDetailsURL.replace("{id}", actionObject.pestData.id) :
						api.fetchPesticideListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editPesticide ? "PATCH" : "POST"
				}).then(pesticideDetails => {
					commit("updatePesticideDetails", { pesticideDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deletePesticide({ commit }, { id, index }) {
			let pesticideID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fetchPesticideDetailsURL.replace("{id}", pesticideID),
					method: "DELETE"
				}).then(() => {
					commit("deletePesticide",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
    },
};
