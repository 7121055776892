import api from "@/api";

const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
		return {
			tables: [
				{
					name: "Subscriptions",
					headers: ["Date", "Plan", "Amount", "Status"],
					data: [
						{
							date: "2021-06-17",
							plan: "Platinum",
							amount: "800",
							status: "Active"
						},
						{
							date: "2021-06-16",
							plan: "Gold",
							amount: "300",
							status: "Inactive"
						},
						{
							date: "2021-06-17",
							plan: "Supreme",
							amount: "4200",
							status: "Inactive"
						},
					],
				},
				{
					name: 'Sales',
					headers: ["Date", "Quantity", "Rate", "Commission"],
					data: [
						{
							date: "2021-06-17",
							quantity: "100",
							rate: "500",
							commission: "15",
						},
						{
							date: "2021-06-16",
							quantity: "200",
							rate: "1000",
							commission: "25",
						},
						{
							date: "2021-06-17",
							quantity: "200",
							rate: "750",
							commission: "20",
						},
					],
				}
			],

			plans: ["Gold", "Platinum", "Supreme"],
			statusList: ["Inactive", "Active"],
		}
	},
	mutations: {
		fetchSubscriptionList(state, subscriptionList) {
			state.tables[0].data = [];
			subscriptionList.forEach(item=>state.tables[0].data.push(backendToFrontendFormatter(item)))
		},
		updateSubscriptionList(state, subscriptionData) {
			let data =backendToFrontendFormatter(subscriptionData)
			state.tables[0].data.push(data);
		},
		deleteSubscription(state, index) {	
				state.tables[0].data.splice(index, 1);
		}
	},
	actions: {
		fetchSubscriptionList({ commit }, farmerID) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin'  ? api.subscriptionListURL.replace("{id}",farmerID):api.executiveSubscriptionListURL.replace("{id}",farmerID),
					method: "GET"
				}).then(subscriptionList => {
					commit('fetchSubscriptionList', subscriptionList);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		updateSubscriptionList({ commit }, { data, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:token === 'admin'? api.subscriptionListURL.replace("{id}",farmerID):api.executiveSubscriptionListURL.replace("{id}",farmerID),
					method: "POST",
					data:frontendToBackendFormatter(data)
				}).then(subscriptionData => {
					commit('updateSubscriptionList', subscriptionData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		deleteSubscription({ commit }, { id,index, miscData }) {
			var token = localStorage.getItem('decoded-token');
			const farmerID = miscData.farmerID;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.subscriptionDetailURL.replace("{user_id}", farmerID).replace("{id}", id):api.executiveSubscriptionDetailsURL.replace("{user_id}", farmerID).replace("{id}", id),
					method: "DELETE"
				}).then(() => {
					commit('deleteSubscription', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
		}
	}
}