import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let expert = JSON.parse(JSON.stringify(originalData));

	return expert;
};

const frontendToBackendFormatter = originalData => {
	let expert = JSON.parse(JSON.stringify(originalData));
	if (expert.expertData.id !== undefined) {
		delete expert.expertData.id
		delete expert.expertData.crop
		return expert.expertData;
	} else {
		return expert.expertData
	}
};

export default {
	state() {
		return {
			loaded: false,
			headers: ["Name", "Phone", "Email", "Department"],
			departments: [
				{
				name: "Soil Science",
				value:"soil science"
				},
				{
					name: "Pathology",
					value:"pathology"
				},
				{
					name: "Agri Economics",
					value:"agri economics"
				},
				{
					name: "Other",
					value:"other"
				}
			],
			tableData: [
				// {
				//     name: "Name 5",
				//     phone: "305-917-1301",
				//     email: "abc@xyz",
				//     department: "Department 9"
				// },
				// {
				//     name: "Name 4",
				//     phone: "210-684-8953",
				//     email: "abc@xyz",
				//     department: "Department 4"
				// },
				// {
				//     name: "Name 1",
				//     phone: "765-338-0312",
				//     email: "abc@xyz",
				//     department: "Department 9"
				// },
				// {
				//     name: "Name 8",
				//     phone: "714-541-3336",
				//     email: "abc@xyz",
				//     department: "Department 7"
				// },
				// {
				//     name: "Name 9",
				//     phone: "972-297-6037",
				//     email: "abc@xyz",
				//     department: "Department 7"
				// },
				// {
				//     name: "Name 6",
				//     phone: "760-318-8376",
				//     email: "abc@xyz",
				//     department: "Department 1"
				// },
				// {
				//     name: "Name 5",
				//     phone: "305-917-1301",
				//     email: "abc@xyz",
				//     department: "Department 9"
				// },
				// {
				//     name: "Name 4",
				//     phone: "210-684-8953",
				//     email: "abc@xyz",
				//     department: "Department 4"
				// },
				// {
				//     name: "Name 1",
				//     phone: "765-338-0312",
				//     email: "abc@xyz",
				//     department: "Department 9"
				// },
				// {
				//     name: "Name 8",
				//     phone: "714-541-3336",
				//     email: "abc@xyz",
				//     department: "Department 7"
				// },
				// {
				//     name: "Name 9",
				//     phone: "972-297-6037",
				//     email: "abc@xyz",
				//     department: "Department 7"
				// },
				// {
				//     name: "Name 6",
				//     phone: "760-318-8376",
				//     email: "abc@xyz",
				//     department: "Department 1"
				// }
			]
		};
	},
	mutations: {
		updateExpertsList(state, { expertList }) {
			state.tableData = [];
			expertList.results.forEach(expert => state.tableData.push(backendToFrontendFormatter(expert)))
			state.isLoaded = true;
		},
		updateExpertDetails(state, { expertDetails }) {
			const index = state.tableData.findIndex(expert => expert.id == expertDetails.id)
			let expert = backendToFrontendFormatter(expertDetails);
			if (index != -1) {
				state.tableData.splice(index, 1, expert);
			} else {
				state.tableData.push(expert);
			}
		},
		deleteExpert(state, index) {
			state.tableData.splice(index, 1)
		}
	},
	actions: {
		fetchExpertsList({ commit },{searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropsExpertsListURL.replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(expertList => {
					commit('updateExpertsList', { expertList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchExpertDetails({ commit }, expertID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropsExpertDetailsURL.replace("{id}", expertID),
					method: "GET"
				}).then(expertDetails => {
					commit("updateExpertDetails", { expertDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setExpertDetails({ commit }, actionObject) {
			let editExpert = actionObject.expertData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editExpert ? api.cropsExpertDetailsURL.replace("{id}", actionObject.expertData.id) :
						api.cropsExpertsListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editExpert ? "PATCH" : "POST"
				}).then(expertDetails => {
					commit("updateExpertDetails", { expertDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteExpert({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropsExpertDetailsURL.replace("{id}",id),
					method: 'DELETE',
				}).then(() => {
					commit('deleteExpert', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
	},
};
