import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let fertilizer = JSON.parse(JSON.stringify(originalData));

	return fertilizer;
};

const frontendToBackendFormatter = originalData => {
	let fertilizer = JSON.parse(JSON.stringify(originalData));
	if(fertilizer.fertData.id!==undefined){
		delete fertilizer.fertData.crop
		delete fertilizer.fertData.id
		return fertilizer.fertData;
	} else {
		return fertilizer.fertData
	}

	
};

export default {
	state() {
		return {
			headers: ['Name', 'Reason for application', 'Method of Application', 'Details'],
            tableData: [
        // [ 'FRT1', 'Spray', '• MOA 1', '• Details lorem ipsum dolor sit amet ksks\n• Skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
        // [ 'HH', 'Chemical', '• MOA 1', '• Details lorsds sdi dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
        // [ 'EOINT', 'Spray', '• MOA 1', '• Sdijsd sum dolor sit\n• Amet ksks skdjs djs dis\n• D sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
        // [ 'KSN2000', 'Spray', '• MOA 1', '• Oh lorem si offer sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds'],
    ],
        loaded: false,
		};
	},
    mutations: {
        updateFerilizerList(state, { fertList }) {
			state.tableData = [];
			fertList.results.forEach(fert => state.tableData.push(backendToFrontendFormatter(fert)))
			state.loaded = true;
		},
		updateFertilizerDetails(state, { fertDetails }) {
			const index = state.tableData.findIndex(fert => fert.id == fertDetails.id)
			let fert = backendToFrontendFormatter(fertDetails);
			if(index != -1) {
				state.tableData.splice(index, 1, fert);
			} else {
				state.tableData.push(fert);
			}
		},
		deleteFertilizer(state, index) {
			state.tableData.splice(index, 1);
		}
    },
    actions: {
        fetchFerilizerList({ commit },{crop,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fertilizersListURL.replace("{crop}", crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(fertList => {
					commit('updateFerilizerList', { fertList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchFertilizerDetails({ commit }, fertID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.fertilizersDetailsURL.replace("{id}", fertID),
					method: "GET"
				}).then(fertDetails => {
					commit("updateFertilizerDetails", { fertDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setFertilizerDetails({ commit },  actionObject ) {
			let editFert = actionObject.fertData.id!= undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editFert ? api.fertilizersDetailsURL.replace("{id}", actionObject.fertData.id) :
						api.fertilizersListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editFert ? "PATCH" : "POST"
				}).then(fertDetails => {
					commit("updateFertilizerDetails", { fertDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteFertilizer({ commit }, { id, index }) {
			let fertID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:  api.fertilizersDetailsURL.replace("{id}", fertID),
					method: "DELETE"
				}).then(() => {
					commit("deleteFertilizer",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
    },
};

