import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let practice = JSON.parse(JSON.stringify(originalData));

	return practice;
};

const frontendToBackendFormatter = originalData => {
	let practice = JSON.parse(JSON.stringify(originalData));
	if(practice.farmPracticeData.id!==undefined){
		delete practice.farmPracticeData.crop
		delete practice.farmPracticeData.id
		return practice.farmPracticeData;
	} else {
		return practice.farmPracticeData
	}   
    
};

export default {
	state() {
		return {
			tableData: [
                // {
                //     name: "Rotating crops and embracing diversity",
                //     details: "• 17th October",
                // },
                // {
                //     name: "Planting cover crops",
                //     details: "• 2nd December",
                // },
                // {
                //     name: "Reducing or eliminating tillage",
                //     details: "• 3rd February",
                // },
                // {
                //     name: "Integrating livestock and crops",
                //     details: "• 1st March",
                // }
            ],
            loaded: false,
		};
	},
    mutations: {
        updateFarmingPracticeList(state, { practiceList }) {
			state.tableData = [];
			practiceList.forEach(practice => state.tableData.push(backendToFrontendFormatter(practice)))
			state.loaded = true;
		},
		updateFarmingPracticeDetails(state, { practiceDetails }) {
			const index = state.tableData.findIndex(practice => practice.id == practiceDetails.id)
			let practice = backendToFrontendFormatter(practiceDetails);
			if(index != -1) {
				state.tableData.splice(index, 1, practice);
			} else {
				state.tableData.push(practice);
			}
		},
		deleteFarmPractice(state, index) {
			state.tableData.splice(index,1)
		}
    },
    actions: {
        fetchFarmingPracticeList({ commit },{crop,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmingPracticeListURL.replace("{crop}",crop).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(practiceList => {
					commit('updateFarmingPracticeList', { practiceList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchFarmingPracticeDetails({ commit }, practiceID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmingPracticeDetailsURL.replace("{id}", practiceID),
					method: "GET"
				}).then(practiceDetails => {
					commit("updateFarmingPracticeDetails", { practiceDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setFarmingPracticeDetails({ commit },  actionObject ) {
			let editPractice = actionObject.farmPracticeData.id!= undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editPractice ? api.farmingPracticeDetailsURL.replace("{id}", actionObject.farmPracticeData.id) :
						api.farmingPracticeListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editPractice ? "PATCH" : "POST"
				}).then(practiceDetails => {
					commit("updateFarmingPracticeDetails", { practiceDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteFarmPractice({ commit }, { id, index }) {
			let practiceID = id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.farmingPracticeDetailsURL.replace("{id}", practiceID),
					method: "DELETE"
				}).then(() => {
					commit("deleteFarmPractice",index );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
    },
};
