import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let variety = JSON.parse(JSON.stringify(originalData));

	return variety;
};

const frontendToBackendFormatter = originalData => {
	let variety = JSON.parse(JSON.stringify(originalData));
	if(variety.cropVarietyData.id!==undefined){
		delete variety.cropVarietyData.crop
		delete variety.cropVarietyData.id
		return variety.cropVarietyData;
	} else {
		return variety.cropVarietyData
	}
};

export default {
	state() {
		return {
			headers: ['SL','Name of Variety', 'Details', 'Speciality'],
            tableData: [
                // [ 'Variety 1', 'Details lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'speciality 1 lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw'],
                // [ 'Variety 2', 'Details lorsds sdi dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'speciality 2 lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw' ],
                // [ 'Variety 3', 'Details Sdijsd sum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'speciality 3 lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw' ],
                // [ 'Variety 4', 'Details lorem si offer sit amet ksks skdjs djs dis d sdisdsd sid wdw. Sdosdoj sodjsi sisd9wnd9nek fjdfdf, ksdjk HJH sds', 'speciality 4 lorem ipsum dolor sit amet ksks skdjs djs dis d sdisdsd sid wdw' ],
            ],
			loaded: false,
			count:null
		};
	},
    mutations: {
		updateCropVarietiesList(state, { varietiesList }) {
			state.count = varietiesList.count;
			state.tableData = [];
			varietiesList.forEach(variety => state.tableData.push(backendToFrontendFormatter(variety)))
			state.isLoaded = true;
		},
		updateCropVarietyDetails(state, { varietyDetails }) {
			const index = state.tableData.findIndex(variety => variety.id == varietyDetails.id)
			let variety = backendToFrontendFormatter(varietyDetails);
			if(index != -1) {
				state.tableData.splice(index, 1, variety);
			} else {
				state.tableData.push(variety);
			}
		},
		deleteCropVariable(state, index) {
			state.tableData.splice(index, 1)
		}
    },
    actions: {
        fetchCropVarietiesList({ commit },{crop,count,searchQuery}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropVarietiesListURL.replace("{crop}",crop).replace("{count}",count).replace("{searchQuery}",searchQuery),
					method: "GET"
				}).then(varietiesList => {
					commit('updateCropVarietiesList', { varietiesList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchCropVarietyDetails({ commit }, varietyID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropVarietyDetailsURL.replace("{id}", varietyID),
					method: "GET"
				}).then(varietyDetails => {
					commit("updateCropVarietyDetails", { varietyDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setCropVarietyDetails({ commit },  actionObject ) {
			let editVariety = actionObject.cropVarietyData.id!= undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editVariety ? api.cropVarietyDetailsURL.replace("{id}", actionObject.cropVarietyData.id) :
						api.cropVarietiesListURL,
					data: frontendToBackendFormatter(actionObject),
					method: editVariety ? "PATCH" : "POST"
				}).then(varietyDetails => {
					commit("updateCropVarietyDetails", { varietyDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteCropVariety({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.cropVarietyDetailsURL.replace("{id}",id),
					method: 'DELETE',
				}).then(() => {
					commit('deleteCropVariable', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
    },
};
