import * as chartConfigs from "@/components/Charts/config";
import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};

const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	if (data.costOptimizeData) {
		if (data.costOptimizeData.id !== undefined) {
			delete data.costOptimizeData.id;
			return data.costOptimizeData;
		} else {
			return data.costOptimizeData;
		}
	} else {
		if (data.incomeExpenseData.id !== undefined) {
			delete data.incomeExpenseData.id;
			return data.incomeExpenseData;
		} else {
			return data.incomeExpenseData;
		}
	}
};

export default {
	state() {
		return {
			loaded: false,
			tables: [
				{
					name: "Income and Expenses",
					headers: ["Date", "Cost", "Income or Expense"],
					data: [
						{
							date: "2021-06-17",
							cost: "500",
							income: "Income",
							description:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						},
						{
							date: "2021-06-16",
							cost: "600",
							income: "Expenditure",
							description:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						},
						{
							date: "2021-06-15",
							cost: "700",
							income: "Income",
							description:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						}
					]
				},
				{
					name: "Cost Optimization",
					headers: ["Date", "Recommendation", "Cost"],
					data: [
						{
							date: "2021-06-17",
							recommendation: "Lorem ipsum dolor sit amet con",
							cost: "500",
							details:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						},
						{
							date: "2021-06-16",
							recommendation: "Lorem ipsum dolor sit amet con",
							cost: "600",
							details:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						},
						{
							date: "2021-06-15",
							recommendation: "Lorem ipsum dolor sit amet con",
							cost: "700",
							details:
								"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nemo vero quas illo exercitationem ullam, maxime voluptatem! Ad, itaque, a consequuntur vitae rem est, perferendis sequi atque nisi quaerat quibusdam."
						}
					]
				}
			],
			incomeList: ["income", "expense"],
			costCategories: [
			],
			chartData: [
				{
					title: "Income vs Year",
					subTitle: "",
					chartData: {
						labels: ["2018", "2019", "2020", "2021", "2022"],
						datasets: [
							{
								label: "income",
								data: [50, 40, 10, 82, 97, 69]
							}
						]
					},
					extraOptions: chartConfigs.blueChartOptions
				},
				{
					title: "Expense vs Year",
					subTitle: "",
					chartData: {
						labels: ["2018", "2019", "2020", "2021", "2022"],
						datasets: [
							{
								label: "Expense",
								data: [95, 40, 90, 72, 77, 19]
							}
						]
					},
					extraOptions: chartConfigs.blueChartOptions
				},
				{
					title: "Income vs Expense",
					subTitle: "",
					chartData: {
						labels: ["500", "400", "100", "800", "900", "600"],
						datasets: [
							{
								label: "Income",
								data: [600, 900, 800, 100, 400, 500]
							}
						]
					},
					extraOptions: chartConfigs.blueChartOptions
				}
			]
		};
	},
	mutations: {
		updateIncomeAndExpensesList(state, { incomeExpenseList }) {
			state.tables[0].data = [];
			incomeExpenseList.results.forEach(data =>
				state.tables[0].data.push(backendToFrontendFormatter(data))
			);
			state.loaded = true;
		},
		updateCostOptimizeList(state, { costOptimizeList }) {
			state.tables[1].data = [];
			costOptimizeList.results.forEach(data =>
				state.tables[1].data.push(backendToFrontendFormatter(data))
			);
			state.loaded = true;
		},
		updateIncomeAndExpensesDetails(state, { incomeExpenseDetails }) {
			const index = state.tables[0].data.findIndex(
				data => data.id == incomeExpenseDetails.id
			);
			let item = backendToFrontendFormatter(incomeExpenseDetails);
			if (index != -1) {
				state.tables[0].data.splice(index, 1, item);
			} else {
				state.tables[0].data.push(item);
			}
		},
		updateCostOptimizeDetails(state, { costOptimizeDetails }) {
			const index = state.tables[1].data.findIndex(
				data => data.id == costOptimizeDetails.id
			);
			let item = backendToFrontendFormatter(costOptimizeDetails);
			if (index != -1) {
				state.tables[1].data.splice(index, 1, item);
			} else {
				state.tables[1].data.push(item);
			}
		},
		updateCostCategoriesList(state,{costCategoriesList}){
			state.costCategories = []
			costCategoriesList.forEach(item=>state.costCategories.push(backendToFrontendFormatter(item)))
		},
		deleteIncomeAndExpense(state, index) {
			state.tables[0].data.splice(index,1)
		},
		deleteCostOptimize(state, index) {
			state.tables[1].data.splice(index,1)
		}
	},
	actions: {
		fetchIncomeAndExpensesList({ commit }, { farmerID, startDate, endDate, land }) {
			var token = localStorage.getItem('decoded-token');
			var date = true;
			if (startDate == null || endDate == null) {
				date = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: date ?(token === 'admin'? api.incomeAndExpensesListURL.replace("{id}", farmerID).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{land}",land):api.executiveIncomeExpenseListURL.replace("{id}", farmerID).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{land}",land)) :
					(token ==='admin' ? api.incomeAndExpensesListURL.replace("{id}", farmerID).replace("{land}",land).replace("start_date={startDate}","").replace("end_date={endDate}",""):api.executiveIncomeExpenseListURL.replace("{id}", farmerID).replace("{land}",land).replace("start_date={startDate}","").replace("end_date={endDate}","")),
					method: "GET"
				})
					.then(incomeExpenseList => {
						commit("updateIncomeAndExpensesList", {
							incomeExpenseList
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchCostOptimizeList({ commit }, { farmerID, startDate, endDate, crop, land }) {
			var token = localStorage.getItem('decoded-token');
			var date = true;
			if (startDate == null || endDate == null) {
				date = false;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: date ? (token==='admin' ?  api.costOptimizeListURL.replace("{id}", farmerID).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{crop}",crop).replace("{land}",land):api.executiveCost_optimizationListURL.replace("{id}", farmerID).replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{crop}",crop).replace("{land}",land)):
					(token === 'admin'? api.costOptimizeListURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land).replace("start_date={startDate}&","").replace("end_date={endDate}&",""):api.executiveCost_optimizationListURL.replace("{id}", farmerID).replace("{crop}",crop).replace("{land}",land).replace("start_date={startDate}&","").replace("end_date={endDate}&","")), 		
					method: "GET"
				})
					.then(costOptimizeList => {
						commit("updateCostOptimizeList", { costOptimizeList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchIncomeAndExpensesDetails({ commit }, { farmerID, itemID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.incomeAndExpensesDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID):api.executiveIncomeExpenseDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				})
					.then(incomeExpenseDetails => {
						commit("updateIncomeAndExpensesDetails", {
							incomeExpenseDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchCostOptimizeDetails({ commit }, { farmerID, itemID }) {
			var token = localStorage.getItem('decoded-token');
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:token ==='admin' ? api.costOptimizeDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID):api.executiveCost_optimizationDetailsURL.replace("{user_id}", farmerID).replace("{id}", itemID),
					method: "GET"
				})
					.then(costOptimizeDetails => {
						commit("updateCostOptimizeDetails", {
							costOptimizeDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setIncomeAndExpensesDetails({ commit }, { actionObject, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			let editMode = actionObject.incomeExpenseData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode ? (token ==='admin' ? api.incomeAndExpensesDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.incomeExpenseData.id).replace("&land={land}", ""):api.executiveIncomeExpenseDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.incomeExpenseData.id).replace("&land={land}", ""))
						: (token ==='admin' ? api.incomeAndExpensesListURL.replace("{id}",farmerID).replace("&land={land}", ""):api.executiveIncomeExpenseListURL.replace("{id}",farmerID).replace("&land={land}", "")),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(incomeExpenseDetails => {
						commit("updateIncomeAndExpensesDetails", {
							incomeExpenseDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setCostOptimizeDetails({ commit }, { actionObject, farmerID }) {
			var token = localStorage.getItem('decoded-token');
			let editMode = actionObject.costOptimizeData.id != undefined;

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: editMode? (token === 'admin' ? api.costOptimizeDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.costOptimizeData.id).replace("&land={land}", "").replace("&crop={crop}", ""):api.executiveCost_optimizationDetailsURL.replace("{user_id}", farmerID).replace("{id}",actionObject.costOptimizeData.id).replace("&land={land}", "").replace("&crop={crop}", ""))
						:(token === 'admin' ? api.costOptimizeListURL.replace("{id}", farmerID).replace("&land={land}", "").replace("&crop={crop}", ""):api.executiveCost_optimizationListURL.replace("{id}", farmerID).replace("&land={land}", "").replace("&crop={crop}", "")),
					data: frontendToBackendFormatter(actionObject),
					method: editMode ? "PATCH" : "POST"
				})
					.then(costOptimizeDetails => {
						commit("updateCostOptimizeDetails", {
							costOptimizeDetails
						});
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchCostCategoriesList({commit},type){
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.costCategoriesListURL.replace("{type}",type),
					method: "GET"
				})
					.then(costCategoriesList => {
						commit("updateCostCategoriesList", { costCategoriesList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteIncomeAndExpense({ commit }, { id, index, miscData }) {
			var token = localStorage.getItem('decoded-token');
			const farmerID = miscData.farmerID;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token === 'admin' ? api.incomeAndExpensesDetailsURL.replace("{id}", id).replace("{user_id}", farmerID):api.executiveIncomeExpenseDetailsURL.replace("{id}", id).replace("{user_id}", farmerID),
					method: "DELETE"
				})
					.then(() => {
						commit("deleteIncomeAndExpense", index);
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		deleteCostOptimize({ commit }, { id, index, miscData }) {
			var token = localStorage.getItem('decoded-token');
			const farmerID = miscData.farmerID;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: token ==='admin' ? api.costOptimizeDetailsURL.replace("{id}", id).replace("{user_id}", farmerID):api.executiveCost_optimizationDetailsURL.replace("{id}", id).replace("{user_id}", farmerID),
					method: "DELETE"
				})
					.then(() => {
						commit("deleteCostOptimize", index);
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		
	}
};
