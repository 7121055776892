import api from '@/api'
const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state() {
        return {
            categoryList: [
				
			],
			categoryDetails:{}

        }
    },
    mutations: {
        updateCategoryList(state, categoryListData) {
			state.categoryList = [];
			categoryListData.forEach(item => state.categoryList.push(backendToFrontendFormatter(item)));
		},
		updateCategoryDetails(state, categoryDetails) {
			let data = backendToFrontendFormatter(categoryDetails);
			state.categoryDetails = data;
            const index = state.categoryList.findIndex(item => item.id == categoryDetails.id);
            if (index !== -1) {
                state.categoryList.splice(index,1,data)
            } else {
                state.categoryList.push(data)
            }
        },
    },
    actions: {
        fetchCategoryList({ commit }) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.categoryListURL,
					method: "GET"
				}).then(categoryListData => {
					commit('updateCategoryList', categoryListData);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		fetchCategoryDetails({commit},id) {
			return new Promise((resolve, reject) => 
				api.actionHandler({
					url: api.categoryDetailURL.replace('{id}',id),
					method: "GET"
				}).then(categoryDetails => {
					commit('updateCategoryDetails', categoryDetails);
					resolve();
				}).catch(errMsg => reject(errMsg))
			);
		},
		setCategoryDetails({ commit }, data) {
			let edit = false;
			if (data.id !== undefined) {
				edit = true;
			}

			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit ? api.categoryDetailURL.replace("{id}",data.id): api.categoryListURL,
					method: edit ? "PATCH" : "POST",
					data: frontendToBackendFormatter(data)
				}).then(categoryDetails => {
					commit('updateCategoryDetails', categoryDetails);
					resolve();
				}).catch(errMsg => reject(errMsg));
			})
        },
    }
}